import countriesCode from "../../../resources/countriesCode.json";
import putServices from "../../../../services/PutAgencyServices";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getServices from "../../../../services/getAgentServices";

const EditProduct = ({
  productInfo,
  onClose,
  companiesData,
  fetchProductsData,
}) => {
  const [changedData, setChangedData] = useState({});
  const [tradingCompanyId, setTradingCompanyId] = useState(
    productInfo.tradingCompanyId
  );
  const [productData, setProductData] = useState(null);
  const { t } = useTranslation(); // i18n // traduccion

  // console.log(productInfo, "infoproduct", "productData");

  // DATA PARA RELLENAR
  // INPUTS CON EL PRODUCTO SELECCIONADO
  const getProductInfo = async () => {
    const endpoint = `/api/product/by-productId/${productInfo.productId}`;
    const service = new getServices();

    try {
      await service.fetchAllWithTradingCompanyId(
        endpoint,
        productInfo.tradingCompanyId
      );
      const response = service.getResponse();

      setProductData(response.product);

      // console.log(response, "info producto");
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChangedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    const endpoint = `/api/product/edit-product/${productInfo.productId}`;

    const dataToPost = { ...changedData };

    // Si se seleccionó una nueva compañía, cambiamos el id
    if (changedData.tradingCompanyId) {
      dataToPost.tradingCompanyId = changedData.tradingCompanyId;
    }
    // console.log(
    //   changedData,
    //   "chage data",
    //   dataToPost,
    //   "data to post",
    //   await tradingCompanyId,
    //   "id comapñia para put"
    // );

    // Instanciando el servicio
    const services = new putServices();
    try {
      //console.log(productInfo);
      await services.putDataWithHeaders(endpoint, dataToPost, tradingCompanyId); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      // console.log(response, tradingCompanyId);
      fetchProductsData(); // Volver a traer los datos para actualizar la tabla
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (changedData.tradingCompanyId) {
      setTradingCompanyId(changedData.tradingCompanyId);
    }
  }, [changedData.tradingCompanyId]);

  useEffect(() => {
    getProductInfo();
  }, []);

  // useEffect(() => {
  //   console.log(productData);
  // }, [productData]);

  return (
    <div className="products w-100 h-100 d-flex flex-column p-5 gap-4 position-relative">
      <div className="d-flex flex-row align-items-center justify-content-start">
        {" "}
        <i
          className="bi bi-arrow-left fs-2 me-4 cursor"
          onClick={onClose}
        ></i>{" "}
        <h1>Editar Fracción Arancelaria</h1>
        {/*  */}
      </div>
      <form className="d-flex flex-column" onSubmit={handleSubmitData}>
        <div className="mb-3">
          <label htmlFor="tariffClassification" className="form-label">
            {t("fraction")}
          </label>
          <input
            type="text"
            className="form-control"
            id="tariffClassification"
            name="tariffClassification"
            placeholder="Fracción"
            value={
              changedData.tariffClassification ||
              productData?.tariffClassification ||
              ""
            }
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="barcode" className="form-label">
            {t("barcode")}
          </label>
          <input
            type="text"
            className="form-control"
            id="barcode"
            name="barcode"
            placeholder="2345678901"
            value={changedData.barcode || productData?.barcode || ""} // Valor por defecto
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="descriptionEs" className="form-label">
            {t("description")}
          </label>
          <input
            type="text"
            className="form-control"
            id="descriptionEs"
            name="descriptionEs"
            placeholder="Descripción del producto"
            value={
              changedData.descriptionEs || productData?.descriptionEs || ""
            } // Valor por defecto
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="tradingCompanyId" className="form-label">
            {t("customer")}
          </label>
          <select
            className="form-control"
            id="tradingCompanyId"
            name="tradingCompanyId"
            value={
              changedData.tradingCompanyId ||
              productData?.tradingCompanyId ||
              ""
            } // Valor por defecto
            onChange={handleInputChange}
          >
            {companiesData?.map((el, index) => (
              <option value={el?._id} key={index}>
                {el?.comercialName}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="tariffClassificationStatus" className="form-label">
            {t("tariffClassificationStatus")}
          </label>
          <select
            className="form-control"
            id="tariffClassificationStatus"
            name="tariffClassificationStatus"
            value={
              changedData.tariffClassificationStatus ||
              productData?.tariffClassificationStatus ||
              ""
            } // Valor por defecto
            onChange={handleInputChange}
          >
            <option value="outdated">Revisar</option>
            <option value="suggested">Camtom</option>
            <option value="approved">Profesional</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="NOMs" className="form-label">
            NOM
          </label>
          <input
            type="text"
            className="form-control"
            id="NOMs"
            name="NOMs"
            placeholder="NOM-050"
            value={changedData.NOMs || productData?.NOMs || ""}
            onChange={handleInputChange}
          />
        </div>

        {/* DOBLE  INPUT */}
        <div class="d-flex flex-row gap-2">
          <div className="mb-3 w-100">
            <label htmlFor="nico" className="form-label">
              NICO
            </label>
            <input
              type="number"
              className="form-control"
              id="nico"
              name="nico"
              placeholder="050"
              onChange={handleInputChange}
            />
          </div>
          {/* <div className="mb-3 w-50">
            <label htmlFor="ID" className="form-label">
              ID
            </label>
            <input
              type="text"
              className="form-control"
              id="ID"
              name="ID"
              placeholder="1234"
              value={productData?._id || ""} // Valor por defecto
              onChange={handleInputChange}
            />
          </div> */}
        </div>

        {/* <div className="mb-3">
          <label htmlFor="marca" className="form-label">
            {t("brand")}
          </label>
          <input
            type="text"
            className="form-control"
            id="marca"
            name="marca"
            placeholder={t("brand")}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="modelo" className="form-label">
            {t("model")}
          </label>
          <input
            type="text"
            className="form-control"
            id="modelo"
            name="modelo"
            placeholder="Modelo"
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="pais" className="form-label">
            {t("country_of_origin")}
          </label>
          <select
            className="form-control"
            id="pais"
            name="pais"
            onChange={handleInputChange}
          >
            <option value="">Seleccione un país</option>
            {countriesCode.countries?.map((el, index) => (
              <option value={el?.code} key={index}>
                {el?.code}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="etiqueta" className="form-label">
            {t("tag")}
          </label>
          <input
            type="text"
            className="form-control"
            id="etiqueta"
            name="etiqueta"
            placeholder="NOM-050"
            onChange={handleInputChange}
          />
        </div> */}

        <button type="submit" className="btn-fill">
          {t("edit_btn")}
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
