import { useNavigate } from "react-router-dom";

function ListQuoterView() {
  const navigate = useNavigate();
  // GO TO PREV VIEW
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="d-flex flex-column p-5">
      {/* TITULO + ICONO */}
      <div className="d-flex flex-row align-items-center">
        <i
          className="bi bi-arrow-left me-3 fs-3 cursor"
          onClick={handleGoBack}
        ></i>
        <h1>Reporte de cotización</h1>
      </div>
      {/* TITULO + ICONO */}

      <div className="d-flex flex-row justify-content-between pe-3 w-100 gap-4">
        <div className="d-flex flex-column w-75">
          <h5>Número de cotización:</h5>
          <p>001</p>
          <h5>Fecha de creación:</h5>
          <p>1 de Junio de 2024</p>
          <h5>Tipo de operación:</h5>
          <p>Importación</p>
          <h5>País de Origen:</h5>
          <p>Brasil</p>
          <h5>País de Destino:</h5>
          <p>Brasil</p>
          <h5>Número de Partidas:</h5>
          <p>001</p>

          <section className="d-flex flex-column border border-2 p-3 rounded w-75">
            <span className="d-flex flex-row justify-content-between p-3 w-100">
              <strong>Valor de la Mercancía</strong>
              <strong>$17,312.58</strong>
            </span>
            <span className="d-flex flex-row justify-content-between align-items-end p-3 w-100">
              <div>
                <p className="m-0">Partida 1</p>
                <p className="m-0">01010101</p>
              </div>
              <strong>$17,312.58</strong>
            </span>
            <span className="d-flex flex-row justify-content-between align-items-end p-3 w-100">
              <div>
                <p className="m-0">Partida 2</p>
                <p className="m-0">01010101</p>
              </div>
              <strong>$17,312.58</strong>
            </span>
          </section>
          <br />
          <section className="d-flex flex-column border border-2 p-3 rounded w-75">
            <span className="d-flex flex-row justify-content-between p-3 w-100">
              <strong>Valor de la Mercancía</strong>
              <strong>$17,312.58</strong>
            </span>
            <span className="d-flex flex-row justify-content-between align-items-end p-3 w-100">
              <div>
                <p className="m-0">Partida 1</p>
                <p className="m-0">01010101</p>
              </div>
              <strong>$17,312.58</strong>
            </span>
            <span className="d-flex flex-row justify-content-between align-items-end p-3 w-100">
              <div>
                <p className="m-0">Partida 2</p>
                <p className="m-0">01010101</p>
              </div>
              <strong>$17,312.58</strong>
            </span>
          </section>
        </div>
        <div className="d-flex flex-column shadow mb-5 bg-body-tertiary rounded p-3 fit-content">
          <span class="d-flex flex-row align-items-end">
            <p>Gastos y pagos arancelarios</p>
            <p>$3,609.21</p>
          </span>
          <hr />
          <span class="d-flex flex-row align-items-end">
            <p>Gastos y pagos arancelarios</p>
            <p>$3,609.21</p>
          </span>
          <hr />
          <span class="d-flex flex-row align-items-end">
            <p>Gastos y pagos arancelarios</p>
            <p>$3,609.21</p>
          </span>
          <button className="w-100 align-self-center btn-camtom py-2 mt-2 fit-content">
            Descargar cotización
          </button>
        </div>
      </div>
    </div>
  );
}
export default ListQuoterView;
