import React, { useEffect, useState } from "react";
import getServices from "../../../../services/getAgentServices";
import { useTranslation } from "react-i18next";
// import "./Products.css";

const CompanyInfoView = ({ companyId, onClose }) => {
  const [companyData, setCompanyData] = useState();
  const { t } = useTranslation(); // i18n // traduccion

  const getProductInfo = async () => {
    const endpoint = `/api/tradingCompany/${companyId}`;
    const service = new getServices();

    try {
      await service.fetchAllWithcustomsAgencyId(endpoint);
      const response = service.getResponse();

      setCompanyData(response);

      console.log(response, "info productos");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, []);

  return (
    <div className="products w-100 h-100 d-flex flex-column p-5 gap-4 position-relative">
      {/* Título + icno "volver" */}
      <div className="d-flex flex-row align-items-center justify-content-start">
        {" "}
        <i
          className="bi bi-arrow-left fs-2 me-4 cursor"
          onClick={onClose}
        ></i>{" "}
        <h1>{t("product_info")}</h1>
      </div>
      <h4>{t("comercial_name")}</h4>
      <p>{companyData?.comercialName}</p>
      <h4>Email</h4>
      {companyData?.email ? (
        <p>{companyData?.email}</p>
      ) : (
        <p>{t("no_added")}</p>
      )}

      <h4>{t("legal_representative_name")}</h4>
      {companyData?.legalData?.legalRepresentativeName ? (
        <p>{companyData?.legalData?.legalRepresentativeName}</p>
      ) : (
        <p>{t("no_added")}</p>
      )}
      <h4>{t("billing_address")}</h4>
      {companyData?.legalAddress.city ? (
        <p>{companyData?.legalAddress.city}</p>
      ) : (
        <p>{t("no_added")}</p>
      )}
      <h4>{t("form_state")}</h4>
      {companyData?.legalAddress.state ? (
        <p>{companyData?.legalAddress.state}</p>
      ) : (
        <p>{t("no_added")}</p>
      )}
    </div>
  );
};

export default CompanyInfoView;
