import { useTranslation } from "react-i18next";
import "./Language.css";
import { useEffect } from "react";
import es from "./iconos/bandera-mexico.svg";
import en from "./iconos/bandera-usa.svg";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // console.log(lng);
  };

  useEffect(() => {
    const getLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const country = data.country;

        // console.log(data);

        const languageMap = {
          US: "en", // Estados Unidos
          ES: "es", // España
          AR: "es", // Argentina
          BO: "es", // Bolivia
          BR: "pt", // Brasil
          CL: "es", // Chile
          CO: "es", // Colombia
          EC: "es", // Ecuador
          GY: "en", // Guyana
          PY: "es", // Paraguay
          PE: "es", // Perú
          SR: "nl", // Surinam
          UY: "es", // Uruguay
          VE: "es", // Venezuela
          // América del Norte
          CA: "en", // Canadá
          MX: "es", // México
          // Otros países de habla inglesa en el Caribe
          AG: "en", // Antigua y Barbuda
          BS: "en", // Bahamas
          BB: "en", // Barbados
          BZ: "en", // Belice
          CR: "es", // Costa Rica
          CU: "es", // Cuba
          DM: "en", // Dominica
          DO: "es", // República Dominicana
          SV: "es", // El Salvador
          GD: "en", // Granada
          GT: "es", // Guatemala
          HT: "fr", // Haití
          HN: "es", // Honduras
          JM: "en", // Jamaica
          NI: "es", // Nicaragua
          PA: "es", // Panamá
          KN: "en", // San Cristóbal y Nieves
          LC: "en", // Santa Lucía
          VC: "en", // San Vicente y las Granadinas
          TT: "en", // Trinidad y Tobago
          // Otros países de habla española en el Caribe
          AN: "nl", // Antillas Neerlandesas
          CW: "nl", // Curazao
          SX: "nl", // Sint Maarten
        };

        const language = languageMap[country];

        changeLanguage(language);
      } catch (err) {
        console.log(err);
      }
    };
    getLocation();
  }, []);

  return (
    <div className="lang">
      <img src={en} alt="us" onClick={() => changeLanguage("en")} />
      <img src={es} alt="es" onClick={() => changeLanguage("es")} />
    </div>
  );
}
export default LanguageSwitcher;
