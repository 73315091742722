import React, { useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { useSelector } from "react-redux";
// import * as XLSX from "xlsx";

import upload_docs from "../../Agents/iconos/upload_docs.svg";
import check from "../../Agents/iconos/check.svg";
import exit from "../../Agents/iconos/exit.svg";

import "./TariffPro.css";
import LoadingScreen from "../../../Loading.js";
import { useTranslation } from "react-i18next";
// import Cookies from "js-cookie";
// import { logOutAgent } from "../../../../redux/reducers.js";
import toast, { Toaster } from "react-hot-toast";
import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";

const TariffProXlsx = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);

  // Idioma
  const { t } = useTranslation();

  const serverUrl = process.env.REACT_APP_serverURLtariff;
  const token = useSelector((state) => state.user.token);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePostToApi = async (url, body) => {
    setLoading(true);
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer", // Correct placement of responseType
      });
      // console.log(response.data);
      convertToXLSX(response.data); // Use this function to handle file download
      setFile(null);
    } catch (error) {
      console.error(error);
      // alert(t("alert_error"));

      setLoading(false);
      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");
      console.log(errorResponse, "variable");

      toast.error(errorResponse, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const convertToXLSX = (data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const downloadLink = window.URL.createObjectURL(blob);
    setFileData(downloadLink); // Set the link for downloading
  };

  const handleSecondButtonClick = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const apiUrl = `${serverUrl}/api/v2/process-excel`;
      await handlePostToApi(apiUrl, formData);
    } else {
      // alert(t("alert_empty"));
      toast.error(t("alert_empty"), {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleExitBtn = () => {
    setFile(null);
  };

  const handleExitXlsxtBtn = () => {
    setFileData(null);
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro">
          <div className="main-content">
            <div className="classifier-section">
              <div className="upload-title">
                {/* <h1>{t("xlsx_title")}</h1> */}
                {/* <p>También puedes subir un archivo en formato PDF, como una factura comercial o una ficha técnica, que contenga descripciones y/o imágenes de los productos que deseas clasificar.</p> */}
              </div>

              <div className="upload-button">
                <h2>1. {t("xlsx_instruction_1")}</h2>
                <p>{t("xlsx_instruction_1_p")}</p>

                <input
                  type="file"
                  id="pdfUpload"
                  accept=".xlsx"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  disabled={loading}
                />
                <label htmlFor="pdfUpload" className="btn">
                  <img
                    src={upload_docs}
                    alt="Icono de Subir"
                    className="upload-icon"
                  />{" "}
                  {t("pdf_placeholder")}
                </label>

                <div className={file ? "product-uploaded" : ""}>
                  <div>
                    {file && <img src={check} alt="" />}
                    {file && file?.name}
                  </div>
                  <span onClick={handleExitBtn}>
                    {file && <img src={exit} alt="" />}
                  </span>
                </div>
              </div>

              <span className="btn btn-camtom-xlsx">
                <a
                  className="text-decoration-none"
                  href="../../../../../public/template_tariffPro_xlsx.xlsx"
                  download={true}
                >
                  Descargar template
                </a>
              </span>

              <div className="button-section">
                <button
                  className="btn-classify"
                  onClick={handleSecondButtonClick}
                  disabled={loading}
                >
                  {t("btn_get_ranking")}
                </button>
              </div>

              {fileData && (
                <div className={fileData ? "product-uploaded" : ""}>
                  <a href={fileData} download="archivo.xlsx">
                    ⬇️ {t("xlsx_download")}
                  </a>
                  <span onClick={handleExitXlsxtBtn}>
                    {file && <img src={exit} alt="" />}
                  </span>
                </div>
              )}

              {/* {!response && <p>Aqui veras tu clasificación.</p>} */}
              {loading && <p>{t("loading_p")}</p>}
              <br />
              <ul>
                {response?.clasifications.map((item, index) => (
                  <li key={index}>
                    <strong>Clasificación:</strong> {item.tariffClass}
                    <br />
                    <strong>Nombre:</strong> {item.name}
                    <br />
                    <strong>Descripción:</strong> {item.description}
                    <br />
                    <br />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffProXlsx;
