import { Routes, Route, Link, useNavigate, Navigate } from "react-router-dom";
// import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

// import axios from 'axios';
//dependencias mapa
import "react-calendar/dist/Calendar.css";
// import Mapbox from 'react-map-gl';

//dependencias calendario
// import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";

import Sidebar from "./Sidebar";
import AgentUsers from "./AgentUsers";

import TariffProImg from "./tariff/TariffProImg";
import TariffProPdf from "./tariff/TariffProPdf";
import TariffProText from "./tariff/TariffProText";
import TariffProXlsx from "./tariff/TariffProXlsx";
import Products from "../Agents/products/Products";
import AddQuoterView from "./quoter/AddQuoterView";
import Digiter from "../../digiter/Digiter";
import Help from "./Help";

import "./DashboardAgent.css";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Companies from "./companies/CompaniesView";
import ListQuoterView from "./quoter/ListQuoterView";
import TariffPro from "./tariff/TariffPro";
import Billing from "../billing/Billing";
import { store } from "../../../redux/store";
// import { logOutAgent } from "../../../redux/reducers";
// import Login from "../../Login";

// const serverURL = process.env.REACT_APP_serverURL;

function AgentDashboard() {
  const token = useSelector((state) => state.agent.token);
  // console.log(tokenAgent, "FUNCION HANDLEISTOKEN");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PrivateRouteAgent = ({ children }) => {
    return token ? children : <Navigate to="/login/customs-agency" replace />;
  };

  return (
    <div className="agent-dashboard">
      <Sidebar />
      <Routes>
        <Route path="/" element={<AgentDashboardPrev />} />
        <Route path="/users" element={<AgentUsers />} />
        <Route path="/tariff-pro-companies" element={<Companies />} />
        <Route path="/new-simulations" element={<AddQuoterView />} />
        <Route path="/my-simulations" element={<ListQuoterView />} />
        <Route path="/digiter" element={<Digiter />} />
        <Route path="/billing" element={<Billing />} />
        <Route
          path="/tariff-pro-clasifications-database"
          element={<Products />}
        />
        <Route
          path="/tariff-pro"
          element={
            <PrivateRouteAgent>
              <TariffPro />
            </PrivateRouteAgent>
          }
        >
          {/* SUBRUTAS DE TARIFF */}
          <Route path="tariff-pro-img" element={<TariffProImg />} />
          <Route path="tariff-pro-pdf" element={<TariffProPdf />} />
          <Route path="tariff-pro-text" element={<TariffProText />} />
          <Route path="tariff-pro-xlsx" element={<TariffProXlsx />} />
        </Route>
        <Route path="/help" element={<Help />} />
      </Routes>
    </div>
  );
}

function AgentDashboardPrev() {
  const { t } = useTranslation(); // i18n // traduccion
  const state = store.getState(); // Estado de redux
  const mail = state.user.email;
  const email = Cookies.get("email");
  console.log(mail);
  // const handleIsToken = () => {
  //   setTimeout(() => {}, 200);
  // };
  return (
    <div className="DashboardAgent">
      <div className="main-content">
        <h1>
          ¡{t("home_welcome")} {email}!
        </h1>

        <div className="card-container">
          <Link
            to={"/agent-dashboard/tariff-pro/tariff-pro-img"}
            // onClick={handleIsToken}
          >
            <div className="card">
              <i
                className="bi bi-file-image me-3 fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
              <p>{t("home_img")}</p>
              <i
                className="bi bi-arrow-right-circle fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
            </div>
          </Link>
          <Link
            to="/agent-dashboard/tariff-pro/tariff-pro-pdf"
            // onClick={handleIsToken}
          >
            <div className="card">
              <i
                className="bi bi-filetype-pdf me-3 fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
              <p>{t("home_pdf")}</p>
              <i
                className="bi bi-arrow-right-circle fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
            </div>
          </Link>
          <Link
            to="/agent-dashboard/tariff-pro/tariff-pro-text"
            // onClick={handleIsToken}
          >
            <div className="card">
              <i
                className="bi bi-justify-left me-3 fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
              <p>{t("home_txt")}</p>
              <i
                className="bi bi-arrow-right-circle fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
            </div>
          </Link>
          <Link to="/agent-dashboard/tariff-pro/tariff-pro-xlsx">
            <div className="card">
              <i
                className="bi bi-filetype-xlsx me-3 fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
              <p>{t("home_xlsx")}</p>
              <i
                className="bi bi-arrow-right-circle fs-1 text-dark"
                // onClick={handleIsToken}
              ></i>
            </div>
          </Link>
          {/* <Link to="/agent-dashboard/tariff-pro/tariff-pro-url" onClick={handleIsToken}>
            <div className="card" onClick={handleIsToken}>
              <i
                className="bi bi-link-45deg me-3 fs-1 text-dark"
                onClick={handleIsToken}
              ></i>
              <p onClick={handleIsToken}>{t("home_url")}</p>
              <i
                className="bi bi-arrow-right-circle fs-1 text-dark"
                onClick={handleIsToken}
              ></i>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default AgentDashboard;
