import React, { useState, useEffect } from "react";
import "./AcceptCookies.css";

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookieConsent")) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
  };

  const rejectCookies = () => {
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div
        className="position-absolute top-50 start-50 translate-middle alert alert-primary py-5 px-3 shadow-lg p-3 mb-5 bg-body-tertiary rounded"
        role="alert"
      >
        <p>
          Este sitio web utiliza cookies para mejorar la experiencia del
          usuario. Al utilizar nuestro sitio, aceptas todas las cookies de
          acuerdo con nuestra <b>política de cookies</b>.
        </p>
        <div class="d-flex flex-row justify-content-evenly">
          <button onClick={acceptCookies} class="btn btn-success m-1">
            Aceptar
          </button>
          <button onClick={rejectCookies} class="btn btn-danger m-1">
            Rechazar
          </button>
        </div>
      </div>
    )
  );
}

export default CookieBanner;
