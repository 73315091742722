import putServices from "../../../../services/PutAgencyServices";
import { useEffect, useState } from "react";
import getServices from "../../../../services/getAgentServices";
import { useTranslation } from "react-i18next";

const EditCompanyView = ({ companyId, onClose, fetchCompanyData }) => {
  const [changedData, setChangedData] = useState({});
  const [companyData, setCompanyData] = useState(null);
  const { t } = useTranslation(); // i18n // traduccion

  console.log(companyId, "edit");

  const getCompany = async () => {
    const endpoint = `/api/tradingCompany/${companyId}`;
    const service = new getServices();

    try {
      await service.fetchAllWithTradingCompanyId(endpoint);
      const response = service.getResponse();
      setCompanyData(response);
      console.log(companyData, companyData.legalAddress, "company");
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChangedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitData = async (e) => {
    e.preventDefault();
    const endpoint = `/api/tradingCompany/${companyId}`;

    const dataToPost = { ...changedData };
    //console.log(dataToPost);

    // Instanciando el servicio
    const services = new putServices();
    try {
      //console.log(companyId);
      await services.patchDataWithHeaders(endpoint, dataToPost); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      //console.log(response);
      fetchCompanyData(); // Volver a traer los datos para actualizar la tabla
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, [changedData.tradingCompanyId]);

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div className="products w-100 h-100 d-flex flex-column p-3 p-5 position-relative">
      <div className="d-flex flex-row align-items-center justify-content-start">
        {" "}
        <i
          className="bi bi-arrow-left fs-2 me-4 cursor"
          onClick={onClose}
        ></i>{" "}
        <h1>{t("edit_company")}</h1>
        {/*  */}
      </div>
      <form className="d-flex flex-column my-5" onSubmit={handleSubmitData}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            className="form-control"
            id="email"
            name="email"
            placeholder="mart@gmail.com"
            value={companyData?.email || companyData?.email || ""}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="comercialName" className="form-label">
            {t("form_importer_name")}
          </label>
          <input
            type="text"
            className="form-control"
            id="comercialName"
            name="comercialName"
            placeholder={t("form_importer_name")}
            value={
              changedData?.comercialName || companyData?.comercialName || ""
            }
            onChange={handleInputChange}
          />
        </div>

        <h5 className="strong my-3">Datos Legales</h5>
        <div className="mb-3">
          <label htmlFor="businessName" className="form-label">
            {t("form_company_business_name")}
          </label>
          <input
            type="text"
            className="form-control"
            id="businessName"
            name="businessName"
            placeholder="MM COMPANY"
            value={
              changedData.legalAddress?.city ||
              companyData?.legalAddress?.city ||
              ""
            }
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="taxID" className="form-label">
            {t("form_RFC_customer")}
          </label>
          <input
            type="text"
            className="form-control"
            d="taxID"
            name="taxID"
            placeholder="443ecddd"
            value={
              changedData.legalAddress?.taxID ||
              companyData?.legalAddress?.taxID ||
              ""
            }
            onChange={handleInputChange}
          />
        </div>

        {/*         <div className="mb-3">
          <label htmlFor="uid" className="form-label">
            UID:
          </label>
          <input
            type="text"
            className="form-control"
            id="uid"
            name="uid"
            placeholder="2233345"
            onChange={handleInputChange}
          />
        </div> */}

        <h5 className="strong my-3">{t("billing_address")}</h5>
        <div className="mb-3">
          <label htmlFor="billingCity" className="form-label">
            {t("city")}
          </label>
          <input
            type="text"
            className="form-control"
            id="billingCity"
            name="billingCity"
            placeholder="CABO LUCAS"
            value={
              changedData.legalAddress?.city ||
              companyData?.legalAddress?.city ||
              ""
            }
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="billingCountry" className="form-label">
            {t("form_country")}
          </label>
          <input
            type="text"
            id="billingCountry"
            name="billingCountry"
            placeholder="MEXICO"
            className="form-control"
            value={
              changedData.legalAddress?.country ||
              companyData?.legalAddress?.country ||
              ""
            }
            onChange={handleInputChange}
          ></input>
        </div>

        <div className="mb-3">
          <label htmlFor="billingExtNumber" className="form-label">
            {t("form_n_ext")}
          </label>
          <input
            type="text"
            id="billingExtNumber"
            name="billingExtNumber"
            placeholder="MANZANA 99"
            value={
              changedData.legalAddress?.extNumber ||
              companyData?.legalAddress?.extNumber ||
              ""
            }
            className="form-control"
          ></input>
        </div>

        <div className="mb-3">
          <label htmlFor="billingIntNumber" className="form-label">
            {t("form_n_int")}
          </label>
          <input
            type="text"
            className="form-control"
            id="billingIntNumber"
            name="billingIntNumber"
            placeholder="LOTE 11"
            value={
              changedData.legalAddress?.intNumber ||
              companyData?.legalAddress?.intNumber ||
              ""
            }
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="btn-fill">
          {t("edit_company")}
        </button>
      </form>
    </div>
  );
};

export default EditCompanyView;
