import React, { useEffect, useState } from "react";
import "./styles/InputModals.css";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import getServices from "../services/getAgentServices";
import postServices from "../services/PostAgencyServices";
import toast, { Toaster } from "react-hot-toast";

const customStyles = {
  content: {
    top: "5%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%)",
    width: "55%",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function InputModalAddFractionToCustomer({
  description,
  tariffClassification,
  imageUrl,
}) {
  // props (get companies, get products)
  let subtitle; // Para el modal (no borrar)
  const [modalIsOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion
  const [companiesData, setCompaniesData] = useState(null);

  //Funciones del form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log(description, tariffClassification);

  const fetchCompaniesData = async () => {
    const endpoint = "/api/tradingCompany/companies/by-customsAgencyId";
    const service = new getServices();
    try {
      await service.fetchAllWithcustomsAgencyId(endpoint);
      const response = service.getResponse(); // Obteniendo la respuesta del servicio

      // console.log(response);
      setCompaniesData(response);
      reset();
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    const endpoint = "/api/product/new-product";
    const dataToPost = {
      descriptionEs: description,
      tariffClassification: tariffClassification,
      imagesLink: imageUrl ? [imageUrl] : [],
      // NOMs: ["NOM-001", "NOM-002"],
      // barCode: "1234567890123",
      // status: "active",
    };
    // console.log(dataToPost, data.cliente, "companyId");
    // Instanciando el servicio
    const services = new postServices();
    try {
      await services.postDataWithHeaders(endpoint, dataToPost, data.cliente); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      console.log(response);
      toast.dismiss(toast.error);

      toast.success("Añadido exitosamente", {
        icon: "✔️",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      reset();
      closeModal();
    } catch (err) {
      console.log(err);

      toast.dismiss(toast.error);

      toast.error(err.response.data.error || "Ocurrió un error", {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // Funciones del modal
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  return (
    <div className="inputModal-add-to-customer">
      <button
        onClick={openModal}
        className="reliability d-flex flex-row justify-content-center gap-2 border text-center w-100 font-gray-strong"
      >
        <i className="bi bi-bookmark font-gray-strong"></i>
        {t("add_to_customer")}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal} //Close btn action
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          ref={(_subtitle) => (subtitle = _subtitle)}
          className="text-center py-2"
        >
          Agregar a Cliente
        </h2>

        <button onClick={closeModal} className="btn-close-modal">
          <i className="bi bi-x fs-4"></i>
        </button>

        {/* Empieza el formulario */}
        <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          {/* <h3 className="my-4">Cliente</h3> */}
          <div className="mb-3">
            <label htmlFor="cliente" className="form-label">
              {t("customer_title")}
            </label>
            <select
              className="form-control"
              id="cliente"
              {...register("cliente", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            >
              {companiesData?.map((el, index) => (
                <option value={el?._id} key={index}>
                  {el?.comercialName}
                </option>
              ))}
            </select>

            {errors.cliente && (
              <span className="span-error">{errors.cliente.message}</span>
            )}
          </div>
          <button type="submit" className="btn-fill">
            Agregar nuevo
          </button>
        </form>
      </Modal>
    </div>
  );
}
export default InputModalAddFractionToCustomer;
