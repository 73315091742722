import React, { useState } from "react";
import "./styles/InputModals.css";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import postServices from "../services/PostAgencyServices";
import { useTranslation } from "react-i18next";
// import getServices from "../services/getServices";

const customStyles = {
  content: {
    top: "5%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%)",
    width: "55%",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function InputModalAddProducts({ fetchProductsData, companiesData }) {
  // props (get companies, get products)
  let subtitle; // Para el modal (no borrar)
  const [modalIsOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log(companiesData);

  const onSubmit = async (data) => {
    const endpoint = "/api/product/new-product";
    const dataToPost = {
      descriptionEs: data.descripcion,
      tariffClassification: data.fraccion,
      tariffClassificationStatus: data.origen,
      unit: "piece",
      status: "active",
    };
    // console.log(dataToPost, data.cliente, "companyId");
    // Instanciando el servicio
    const services = new postServices();
    try {
      await services.postDataWithHeaders(endpoint, dataToPost, data.cliente); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      console.log(response);
      fetchProductsData(); // Volver a traer los datos para actualizar la tabla
      reset();
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  // Funciones del modal
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="inputModal">
      <button
        onClick={openModal}
        className="btn-primary d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-plus fs-4"></i>
        <span>{t("add_new_fraction")}</span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal} //Close btn action
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          ref={(_subtitle) => (subtitle = _subtitle)}
          className="text-center py-2"
        >
          {t("add_new_fraction")}{" "}
        </h2>

        <button onClick={closeModal} className="btn-close-modal">
          <i className="bi bi-x fs-4"></i>
        </button>

        {/* Empieza el formulario */}
        <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="fraccion" className="form-label">
              {t("fraction")}
            </label>
            <input
              type="text"
              className="form-control"
              id="fraccion"
              placeholder={t("fraction")}
              {...register("fraccion", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.fraccion && (
              <span className="span-error">{errors.fraccion.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="descripcion" className="form-label">
              {t("description")}
            </label>
            <input
              type="text"
              className="form-control"
              id="descripcion"
              placeholder={t("description")}
              {...register("descripcion", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.descripcion && (
              <span className="span-error">{errors.descripcion.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="cliente" className="form-label">
              {t("customer")}
            </label>
            <select
              className="form-control"
              id="cliente"
              {...register("cliente", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            >
              {companiesData?.map((el, index) => (
                <option value={el?._id} key={index}>
                  {el?.comercialName}
                </option>
              ))}
            </select>

            {errors.cliente && (
              <span className="span-error">{errors.cliente.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="origen" className="form-label">
              Origen
            </label>
            <select
              className="form-control"
              id="origen"
              {...register("origen", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            >
              <option value="">{t("select_source")}</option>
              <option value="outdated">{t("outdated")}</option>
              <option value="suggested">{t("suggested")}</option>
              <option value="approved">{t("approved")}</option>
            </select>

            {errors.origen && (
              <span className="span-error">{errors.origen.message}</span>
            )}
          </div>

          <button type="submit" className="btn-fill">
            {t("add_new")}
          </button>
        </form>
      </Modal>
    </div>
  );
}
export default InputModalAddProducts;
