import { useEffect, useState } from "react";
import getServices from "../../../../services/getAgentServices";
import CompanyInfoView from "./CompanyInfoView";
import EditCompanyView from "./EditCompanyView";
import InputModalAddCompanies from "../../../../Modals/InputModalAddCompanies";
import { useTranslation } from "react-i18next";

const Companies = () => {
  const [companyId, setCompanyId] = useState();
  const [data, setData] = useState();
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showEditCompany, setShowEditCompany] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion

  // GET DATA TO FILL COMPANIES TABLE
  const fetchCompanyData = async () => {
    const endpoint = "/api/tradingCompany/companies/by-customsAgencyId";
    const services = new getServices();
    try {
      await services.fetchAllWithcustomsAgencyId(endpoint); //Llamando al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio
      setData(response);
      //console.log(response, "companies");
    } catch (err) {
      console.log(err);
    }
  };

  // TOGGLE COMPANY INFO VIEW
  const handleShowCompaniesInfo = (CompanyId) => {
    setShowCompanyInfo(true);
    setCompanyId(CompanyId);
    // console.log(CompanyId);
  };
  // EDIT COMPANY INFO VIEW
  const handleEditproduct = (CompanyId) => {
    setCompanyId(CompanyId);
    setShowEditCompany(true);
  };

  // FILTERS FUNCTIONS
  const handleSort = (el) => {
    //console.log(el.target.value);
    const sortDataByName = [...data].sort((a, b) =>
      a.comercialName < b.comercialName
        ? -1
        : a.comercialName > b.comercialName
        ? 1
        : 0
    );

    const SortDataByDate = [...data].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    if (el.target.value === "nombre") return setData(sortDataByName);
    if (el.target.value === "reciente") return setData(SortDataByDate);
  };

  const handleSearch = (e) => {
    const catchKey = e.target.value
      .trim()
      .toLowerCase()
      .replace(/[áäàâ]/g, "a")
      .replace(/[éëèê]/g, "e")
      .replace(/[íïìî]/g, "i")
      .replace(/[óöòô]/g, "o")
      .replace(/[úüùû]/g, "u");

    //console.log(catchKey);

    const found = data.filter((el) => {
      return el.comercialName
        .toLowerCase()
        .replace(/[áäàâ]/g, "a")
        .replace(/[éëèê]/g, "e")
        .replace(/[íïìî]/g, "i")
        .replace(/[óöòô]/g, "o")
        .replace(/[úüùû]/g, "u")
        .includes(catchKey);
    });
    setData(found);

    if (catchKey === "") {
      fetchCompanyData();
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  return showCompanyInfo ? (
    <CompanyInfoView
      companyId={companyId}
      onClose={() => setShowCompanyInfo(false)} // Pasando funcion para cerrar la vista
    />
  ) : showEditCompany ? (
    <EditCompanyView
      companyId={companyId}
      fetchCompanyData={fetchCompanyData}
      onClose={() => setShowEditCompany(false)}
    />
  ) : (
    <>
      <div class="products w-100 h-100 d-flex flex-column p-5 gap-4">
        <h1>{t("customer_title")}</h1>
        <div className="d-flex justify-content-evenly w-100 input-product">
          {/* Input + icono */}
          <div className="input-icon d-flex justify-content-start align-items-center">
            <i className="bi bi-search"></i>
            <input
              onChange={handleSearch}
              type="text"
              name=""
              className="w-100"
              placeholder={t("search_by_RFC")}
            />
          </div>
          {/* Input + icono */}

          <InputModalAddCompanies fetchCompanyData={fetchCompanyData} />
          {/* Aquí va un popup */}
        </div>
        <section
          className="d-flex align-items-center w-100 gap-2"
          onChange={handleSort}
        >
          <select name="ordenar" id="" className="select-cliente">
            <option value="nombre">{t("customer_name")}</option>
            <option value="reciente">{t("recently")}</option>
          </select>
          {/* <i className="bi bi-arrow-up-circle fs-3"></i> */}
        </section>

        {/* table */}
        <section>
          {/* thead */}
          <div className="d-flex flex-row align-items-center w-100 border-bottom px-3 gap-2">
            <strong className="my-0 p-2 w-20 text-center">
              {t("importer")}
            </strong>
            <strong className="my-0 p-2 w-20 text-center">
              {t("business_name")}
            </strong>
            <strong className="my-0 p-2 w-20 text-center">RFC</strong>
            <strong className="my-0 p-2 text-center w-20">{t("city")}</strong>
            <strong className="my-0 p-2 text-center w-20">
              {t("actions")}
            </strong>
          </div>

          {/* body */}
          {data?.map((el, index) => (
            <div
              className="d-flex align-items-center justify-content-between w-100 border-bottom px-3 gap-2 cursor"
              key={index}
            >
              <p
                className="my-0 p-2 w-20 text-center"
                onClick={() => handleShowCompaniesInfo(el?._id)}
              >
                {el?.comercialName}
              </p>
              <p
                className="my-0 p-2 w-20 text-center"
                onClick={() => handleShowCompaniesInfo(el?._id)}
              >
                {el?.legalData?.businessName}
              </p>
              <p
                className="my-0 p-2 w-20 text-center"
                onClick={() => handleShowCompaniesInfo(el?._id)}
              >
                {el?.legalData?.taxID}
              </p>
              <p
                className="my-0 p-2 w-20 text-center"
                onClick={() => handleShowCompaniesInfo(el?._id)}
              >
                {el?.legalAddress?.city}
              </p>
              <i
                class="bi bi-pencil-square fs-3 text-center w-20"
                onClick={() => handleEditproduct(el?._id)}
              ></i>
            </div>
          ))}
        </section>
      </div>
    </>
  );
};

export default Companies;
