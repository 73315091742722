// Vamos a manejar todas las peticiones get con esta clase
import { store } from "../redux/store";
const URL = process.env.REACT_APP_serverURL;

class getServices {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }

  async fetchAll(endpoint) {
    try {
      const res = await fetch(`${URL}${endpoint}`);
      console.log(URL, endpoint);
      const json = await res.json();

      this.response = json;
      // return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  // Para pasar el tradingComapanyId por el header
  async fetchAllWithTradingCompanyId(endpoint) {
    const state = store.getState();
    const tradingCompanyId = state.user.tradingCompanyId;
    // console.log(state);
    try {
      const headers = {
        tradingCompanyId,
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const res = await fetch(`${URL}${endpoint}`, options);
      const json = await res.json();

      this.response = json;
      console.log(this.response);
      // console.log(json, "respuesta get services con trading");
      // return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default getServices;
