import React, { useEffect, useState } from "react";

import "./Products.css";
import InputModalAddProducts from "../../../../Modals/InputModalAddProduct";
import ProductInfoView from "./ProductInfoView";
import getServices from "../../../../services/getAgentServices";
import EditProduct from "./EditproductView";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [data, setData] = useState();
  const [companiesData, setCompaniesData] = useState();
  const [productInfo, setProductInfo] = useState({
    tradingCompanyId: null,
    productId: null,
  });
  const [showProductInfo, setShowProductInfo] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion

  // GET DATA TO FILL PRODUCT TABLE
  const fetchProductsData = async () => {
    const endpoint = "/api/product/by-customsagency?pag_begin=0&pag_end=50";
    const services = new getServices();
    try {
      await services.fetchAllWithcustomsAgencyId(endpoint); //Llamando al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      setData(response.customsAgencyProducts);
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  // GET DATA TO FILL THE SELECT FORM
  const fetchCompaniesData = async () => {
    const endpoint = "/api/tradingCompany/companies/by-customsAgencyId";
    const services = new getServices();

    try {
      await services.fetchAllWithcustomsAgencyId(endpoint); //Llamando al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      // console.log(response);

      setCompaniesData(response);
    } catch (err) {
      console.error(err);
    }
  };

  // TOGGLE PRODUCT INFO VIEW
  const handleShowProductInfo = (ProductId, tradingCompanyId) => {
    setProductInfo({
      tradingCompanyId: tradingCompanyId,
      productId: ProductId,
    });
    setShowProductInfo(true);
    // console.log(productInfo);
  };

  const handleEditproduct = (ProductId, tradingCompanyId) => {
    setProductInfo({
      tradingCompanyId: tradingCompanyId,
      productId: ProductId,
    });
    setShowEditProduct(true);
  };

  useEffect(() => {
    fetchProductsData();
    fetchCompaniesData();
    // console.log(editProduct);
  }, []);

  return showProductInfo ? (
    <ProductInfoView
      productInfo={productInfo}
      onClose={() => setShowProductInfo(false)} // Pasando funcion para cerrar la vista
    />
  ) : showEditProduct ? (
    <EditProduct
      productInfo={productInfo}
      companiesData={companiesData}
      fetchProductsData={fetchProductsData}
      onClose={() => setShowEditProduct(false)}
    />
  ) : (
    <div className="products w-100 h-100 d-flex flex-column p-5 gap-4">
      <h1>{t("clasifications-database")}</h1>
      <div className="d-flex justify-content-evenly w-100 input-product">
        {/* Input + icono */}
        <div className="d-flex justify-content-start w-100 align-items-center">
          {/* input-icon Añadir la clase al div de arriba para mostrar el input */}
          {/* <i className="bi bi-search"></i>
            <input
              type="text"
              name=""
              className="w-100"
              placeholder="Buscar por fracción arancelaria o decripción del producto"
            /> */}
        </div>
        {/* Input + icono */}

        <InputModalAddProducts
          companiesData={companiesData}
          fetchProductsData={fetchProductsData}
        />
        {/* Aquí va un popup */}
      </div>
      {/* Seccion filtros */}
      {/* <section className="d-flex align-items-center w-100 gap-2">
          <select name="ordenar" id="" className="select-cliente">
            <option value="">Nombre del cliente</option>
            <option value="">Fraccíon</option>
            <option value="">Recién agregado</option>
          </select>
          <i className="bi bi-arrow-up-circle fs-3"></i>
          <select name="filtrar" id="" className="select-cliente">
            <option value="">Cliente</option>
            <option value="">ABC SUMINISTROS</option>
            <option value="">ABC SUMINISTROS</option>
          </select>
          <div class="btn">
            <span>ABC SUMINISTROS</span>
            <i className="bi bi-arrow-x fs-3"></i>
          </div>
        </section> */}

      {/* table */}
      <section>
        {/* thead */}
        <div className="d-flex flex-row align-items-center w-100 border-bottom px-3 gap-2">
          <strong className="my-0 p-2 w-20">{t("fraction")}</strong>
          <strong className="my-0 p-2 w-50">{t("description")}</strong>
          <strong className="my-0 p-2 text-center w-20">
            {t("classification_source")}
          </strong>
          <strong className="my-0 p-2 text-center w-20">{t("Actions")}</strong>
        </div>

        {/* body */}
        {data?.map((el, index) => (
          <div
            className="d-flex align-items-center justify-content-between w-100 border-bottom py-2 gap-2 cursor"
            key={index}
          >
            <p
              className="my-0 p-2 w-20"
              onClick={() =>
                handleShowProductInfo(el?._id, el.tradingCompanyId)
              }
            >
              {el?.tariffClassification}
            </p>
            <p
              className="my-0 p-2 w-50"
              onClick={() =>
                handleShowProductInfo(el?._id, el.tradingCompanyId)
              }
            >
              {el?.descriptionEs}
            </p>
            <p
              className={`my-0 p-1 rounded text-center w-20 ${
                el?.tariffClassificationStatus === "approved"
                  ? "verde"
                  : el?.tariffClassificationStatus === "outdated"
                  ? "rojo"
                  : el?.tariffClassificationStatus === "suggested"
                  ? "amarillo"
                  : null
              }`}
              onClick={() =>
                handleShowProductInfo(el?._id, el.tradingCompanyId)
              }
            >
              {el?.tariffClassificationStatus === "approved"
                ? "Profesional"
                : el?.tariffClassificationStatus === "outdated"
                ? "Revisar"
                : el?.tariffClassificationStatus === "suggested"
                ? "Camtom"
                : null}
            </p>
            <i
              class="bi bi-pencil-square fs-3 text-center w-20"
              onClick={() => handleEditproduct(el?._id, el.tradingCompanyId)}
            ></i>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Products;
