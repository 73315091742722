// Vamos a manejar todas las peticiones get con esta clase
import { store } from "../redux/store";
const URL = process.env.REACT_APP_serverURL;

class postServices {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }
  // TADING COMPANY ID
  async postDataWithHeaders(endpoint, body) {
    const state = store.getState();
    const tradingCompanyId = state.user.tradingCompanyId;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tradingCompanyId,
      },
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(`${URL}${endpoint}`, options);
      console.log(URL, endpoint);
      const json = await res.json();

      this.response = json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  // TOKEN
  async postDataWithToken(body) {
    const urlQuoter = "https://pyapi.camtomx.com/api/v2/quote/new-quote";

    const state = store.getState();

    let token;
    if (state.agent.token !== null) {
      token = state.agent.token;
    }

    if (state.user.token !== null) {
      token = state.user.token;
    }

    const options = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };

    console.log(urlQuoter, body);
    try {
      const res = await fetch(urlQuoter, options);

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(
          `HTTP error! status: ${res.status}, message: ${errorText}`
        );
      }

      const json = await res.json();
      this.response = json;
      console.log(json);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default postServices;
