import { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import getServices from "../../../services/getAgentServices";
import toast, { Toaster } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";

// Este es el billing para compañía xq lleva el id de compañía

const BillingCompany = () => {
  const state = store.getState();
  const [sessionId, setSessionId] = useState(null);
  const [sliderTypeOperation, setSliderTypeOperation] = useState(false);
  const [priceIdStarter, setPriceIdStarter] = useState(
    process.env.REACT_APP_PRICE_ID_PRO_YEARLY_STRIPE
  );
  const [priceIdPro, setPriceIdPro] = useState(
    process.env.REACT_APP_PRICE_ID_PRO_YEARLY_STRIPE
  );
  const [billingResponse, setBillingResponse] = useState({
    beginDate: null,
    expirationDate: null,
    status: null,
    limit_camtomDocsPages: null,
    limit_tariffPro: null,
  });
  const tradingCompanyId = state.user.token;
  const tradingCompanyEmployeeId = state.user.tradingCompanyId;

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  // Compra de membresía
  const createCheckoutSession = async (price, tier) => {
    // console.log(price);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        priceId: price,
        tradingCompanyEmployeeId,
        tradingCompanyId,
        tier,
      }),
    };
    console.log(
      `${process.env.REACT_APP_serverURL}/api/stripe/create-checkout-session`,
      price,
      tradingCompanyEmployeeId,
      tradingCompanyId,
      tier
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_serverURL}/api/stripe/create-checkout-session`,
        options
      );
      const data = await response.json();
      // console.log(data);
      setSessionId(data.sessionId);
    } catch (error) {
      console.error("Error creando la sesión de checkout:", error);
    }
  };

  useEffect(() => {
    // hacer una llamada a api para verificar datos de membresía
    let begin, expiration;
    const getUserSuscription = async () => {
      const endpoint = "/api/tradingCompany/subscription/info";

      const service = new getServices();

      await service.fetchAllWithTradingCompanyIdAndTestingURL(endpoint);
      const response = service.getResponse();
      // Formateando fechas
      begin = new Date(response.beginDate);
      expiration = new Date(response.expirationDate);
      // console.log(response);
      // reasigando valores
      setBillingResponse((prevState) => ({
        ...prevState,
        beginDate: begin.toLocaleDateString("sv-SE"),
        expirationDate: expiration.toLocaleDateString("sv-SE"),
        status: response?.status.toUpperCase(),
        limit_camtomDocsPages: response?.limits.limit_camtomDocsPages,
        limit_tariffPro: response?.limits.limit_tariffPro,
      }));
    };

    getUserSuscription();
  }, []);

  useEffect(() => {
    //
    const redirectToCheckout = async (event) => {
      toast.error("Procesando...", {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          background: "#33CAFF",
          color: "#fff",
        },
      });
      // Obtener el stripe object
      const stripe = await stripePromise;

      // Redirigir a Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (error) {
        console.error("Error redirigiendo a Stripe Checkout:", error);
        toast.error("Error redirigiendo a Stripe Checkout", {
          icon: "❗❗",
          style: {
            borderRadius: "10px",
            background: "#FF3352",
            color: "#fff",
          },
        });
      }
    };
    if (sessionId) {
      redirectToCheckout();
    }
  }, [sessionId, stripePromise]);

  const changeTypeOeration = () => {
    setSliderTypeOperation(!sliderTypeOperation);
  };

  useEffect(() => {
    if (sliderTypeOperation) {
      setPriceIdStarter(process.env.REACT_APP_PRICE_ID_STARTER_MONTHLY_STRIPE);
      setPriceIdPro(process.env.REACT_APP_PRICE_ID_PRO_MONTHLY_STRIPE);
    } else {
      setPriceIdStarter(process.env.REACT_APP_PRICE_ID_STARTER_YEARLY_STRIPE);
      setPriceIdPro(process.env.REACT_APP_PRICE_ID_PRO_YEARLY_STRIPE);
    }
    console.log(sliderTypeOperation);
  }, [sliderTypeOperation]);

  return (
    <div className="d-flex flex-column align-items-center p-3 py-5 gap-4 w-100 h-100 overflow-y-auto">
      <h1 className="w-100">Mis planes</h1>
      <section class="d-flex flex-column w-100 shadow-lg border-0 rounded-2 p-5">
        {/* t-Head 1 */}
        <span class="d-flex flex-row gap-2 mb-2">
          <strong className="w-50">Fecha de inicio</strong>
          <strong className="w-50">Fecha de expiración</strong>
          <strong className="w-50">Membresía</strong>
        </span>
        {/* T-Body 1 */}
        <span class="d-flex flex-row gap-2 mb-2">
          <p className="w-50">{billingResponse.beginDate}</p>
          <p className="w-50">{billingResponse.expirationDate}</p>
          <span className="w-50">
            <p className="btn btn-success">
              {billingResponse.status ? (
                billingResponse.status
              ) : (
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </p>
          </span>
        </span>

        <span class="d-flex flex-row gap-2 mb-2">
          <strong className="w-10">Tariff pro</strong>
          <p className="w-10">{billingResponse.limit_tariffPro}</p>
        </span>
      </section>

      <div className="d-flex flex-column w-100 align-items-center">
        {/* Btn anual/mensual */}
        <div className="w-50 d-flex alig-items-center justify-content-center">
          <button
            className="slider-btn-container-billing position-relative fs-6"
            onClick={changeTypeOeration}
          >
            <span
              className={`btn-slider-user ${
                sliderTypeOperation ? "slide-true-billing" : "slide-false"
              }`}
            ></span>
            <span className="d-flex flex-row align-items-center absolute-text">
              <p
                className={`my-0 w-50 ${
                  sliderTypeOperation
                    ? "active-btn-slider"
                    : "inactive-btn-slider"
                }`}
              >
                Mensualidad
              </p>
              <p
                className={`my-0 w-50 ${
                  sliderTypeOperation
                    ? "inactive-btn-slider"
                    : "active-btn-slider"
                }`}
              >
                Anualidad
              </p>
            </span>
          </button>
        </div>
        <div className="d-flex flex-row flex-sm-wrap flex-md-wrap flex-lg-wrap flex-xl-wrap justify-content-sm-center w-100 gap-5 p-3 pb-5 mt-3">
          {/* User billing info */}

          {/* Starter */}
          <div className="card w-200 shadow-lg border-0">
            <div className="card-body">
              <h5 className="card-title fw-bold">Starter</h5>
              <button
                className="btn-camtom w-100 rounded-pill my-3"
                onClick={() => createCheckoutSession(priceIdStarter, "starter")}
              >
                Actualizar a Starter ahora
              </button>
              <p className="card-text my-3 fw-bold">Qué obtienes:</p>
              <ul>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Hasta 250 clasificaciones cada mes</p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>
                    Clasifica productos desde archivos en PDF, Excel, imágenes y
                    texto
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>
                    Regulación y normatividad arancelaria por cada Fracción
                    Arancelaria
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Base de Consulta e Histórico de Clasificaciones</p>
                </li>

                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Integración de API</p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-x-circle-fill text-secondary"></i>
                  <p>
                    Integración de API Canal en Slack o WhatsApp dedicado a
                    soporte prioritario
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-x-circle-fill text-secondary"></i>
                  <p>Pricing personalizado</p>
                </li>
              </ul>
            </div>
          </div>
          {/* Pro */}
          <div className="card w-200 shadow-lg border-0">
            <div className="card-body">
              <h5 className="card-title fw-bold">Pro</h5>
              <button
                className="btn-camtom w-100 rounded-pill my-3"
                onClick={() => createCheckoutSession(priceIdPro, "pro")}
              >
                Actualizar a Pro ahora
              </button>
              <p className="card-text my-3 fw-bold">Qué obtienes:</p>
              <ul>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Hasta 1000 clasificaciones cada mes</p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>
                    Clasifica productos desde archivos en PDF, Excel, imágenes y
                    texto
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>
                    Regulación y normatividad arancelaria por cada Fracción
                    Arancelaria
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Base de Consulta e Histórico de Clasificaciones</p>
                </li>

                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>Integración de API</p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-check-circle-fill text-primary"></i>
                  <p>
                    Integración de API Canal en Slack o WhatsApp dedicado a
                    soporte prioritario
                  </p>
                </li>
                <li className="list-unstyled d-flex flex-row gap-2 my-2">
                  <i className="bi bi-x-circle-fill text-secondary"></i>
                  <p>Pricing personalizado</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default BillingCompany;
