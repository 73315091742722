import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import Cookies from "js-cookie";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: Cookies.get("tokenUser") ? Cookies.get("tokenUser") : null,
    tradingCompanyId: Cookies.get("tradingCompanyId") || null,
  },
  reducers: {
    loginUser: (state, action) => {
      state.token =  action.payload.token ;
      state.tradingCompanyId = action.payload.tradingCompanyId;
      Cookies.set("tokenUser", action.payload.token, { expires: 3 });
      Cookies.set("tradingCompanyId", action.payload.tradingCompanyId, { expires: 3 });
    },
    logOutUser: (state) => {
      state.token = null;
      state.tradingCompanyId = null;
      Cookies.remove("tokenUser");
      Cookies.remove("tradingCompanyId");
    },
  },
});

const agentSlice = createSlice({
  name: "agent",
  initialState: {
    token: Cookies.get("tokenAgent") ? Cookies.get("tokenAgent") : null,
    email: Cookies.get("email") || null,
    customsAgencyId: Cookies.get("customsAgencyId") || null,
    agentId: Cookies.get("agentId") || null,
  },
  reducers: {
    loginAgent: (state, action) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.customsAgencyId = action.payload.customsAgencyId;
      state.agentId = action.payload.agentId;
      Cookies.set("tokenAgent", action.payload.token, { expires: 3 });
      Cookies.set("email", action.payload.email, { expires: 3 });
      Cookies.set("customsAgencyId", action.payload.customsAgencyId, { expires: 3 });
      Cookies.set("agentId", action.payload.agentId, { expires: 3 });
    },
    logOutAgent: (state) => {
      state.token = null;
      state.email = null;
      state.customsAgencyId = null;
      state.agentId = null;
      Cookies.remove("tokenAgent");
      Cookies.remove("email");
      Cookies.remove("customsAgencyId");
      Cookies.remove("agentId");
    },
  },
});

export const { loginUser, logOutUser } = userSlice.actions;
export const { loginAgent, logOutAgent } = agentSlice.actions;

const rootReducer = combineReducers({
  user: userSlice.reducer,
  agent: agentSlice.reducer,
});

export default rootReducer;
