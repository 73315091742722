import React, { useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { storage } from "../../../../firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSelector } from "react-redux";

import image_upload from "../iconos/image-upload-section.svg";
import check from "../iconos/check.svg";
import exit from "../iconos/exit.svg";

import "./TariffPro.css";
// import LoadingScreen from "../../../Loading.js";
import { useTranslation } from "react-i18next";
import InputModalAddFractionToCustomer from "../../../../Modals/InputModalAddFractionToCustomer.js";
import toast, { Toaster } from "react-hot-toast";
import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import { useNavigate } from "react-router-dom";
import TariffNoms from "../../tariffCommon/TariffNoms.js";
import TariffNearly from "../../tariffCommon/TariffNearly.js";

const TariffPro = () => {
  const [image, setImage] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNearly, setShowNearly] = useState(false);
  const [showNearlySubheading, setShowNearlySubheading] = useState(null);
  const [showNearlyFraction, setShowNearlyFraction] = useState(null);
  const [partida, setPartida] = useState(null);
  const [subPartida, setSubPartida] = useState(null);
  const [fraction, setFraction] = useState(null);

  const { t } = useTranslation(); //Idioma
  const navigate = useNavigate();

  const serverUrl = process.env.REACT_APP_serverURLtariff;
  const token = useSelector((state) => state.agent.token);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const uploadImage = async (file) => {
    setLoading(true);
    try {
      const fileRef = ref(storage, `tariffPro/files/${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      setImageUrl(url);
      console.log("File uploaded successfully:", url);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePostToApi = async (url, body) => {
    try {
      // console.log(body);
      const response = await axios.post(url, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      // Handle the response as needed
      if (typeof response.data === "string") {
        setResponse(JSON.parse(response.data));
      } else {
        setResponse(response.data);

        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      //
      setLoading(false);
      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");
      console.log(errorResponse, error, "variable");

      toast.error(errorResponse, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleFirstButtonClick = async () => {
    setLoading(true);
    let body = {};

    if (image) {
      body.imgUrl = imageUrl;
    }

    if (prompt) {
      body.userDescription = prompt;
    }

    if (!image && !prompt) {
      // setShowAlert(t("img_alert_empty_file"));
      setLoading(false);
      toast.error(t("img_alert_empty_file"), {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      return;
    }
    console.log("");
    const apiUrl = `${serverUrl}/api/v2/text-image`;
    // text-image
    await handlePostToApi(apiUrl, body);
    // setLoading(false);
  };

  const handleExitBtn = () => {
    setImage(null);
  };

  const handleCopyCode = (e) => {
    console.log(e);

    try {
      navigator.clipboard.writeText(e);

      // setLoading(false);
      setTimeout(() => {
        toast.error(t("¡Texto copiado en el portapapeles!"), {
          icon: "❗❗",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);
    } catch (err) {
      // setLoading(false);
      toast(t("Error al copiar el código"), {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleRedirectToQuoter = (fraction) => {
    navigate(`/agent-dashboard/new-simulations`, { state: { key: fraction } });
    console.warn(fraction);
  };

  const toggleShowNearly = (index, partida) => {
    setShowNearly(showNearly === index ? null : index);
    setPartida(partida);
  };

  const toggleShowNearlySubheading = (index, subheading) => {
    setShowNearlySubheading(showNearlySubheading === index ? null : index);
    setSubPartida(subheading);
  };

  const toggleShowNearlyFraction = (index, fraction) => {
    setShowNearlyFraction(showNearlyFraction === index ? null : index);
    setFraction(fraction);
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              {/* <h1>{t("img_title")}</h1> */}
              <div className="upload-instructions">
                <h2>1. {t("img_instruction_1")}</h2>
              </div>

              <div className="upload-area">
                <input
                  type="file"
                  id="fileUpload"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  disabled={loading}
                />
                <label htmlFor="fileUpload" className="dashed-border">
                  <img src={image_upload} alt="Icono de Subir" />
                  <h3>{t("img_drag_title")}</h3>
                  <h4>{t("img_drag_description")}</h4>
                </label>
                <div className={image ? "product-uploaded" : ""}>
                  <div>
                    {image && <img src={check} alt="" />}
                    {image && image?.name}
                  </div>
                  <span onClick={handleExitBtn}>
                    {image && <img src={exit} alt="" />}
                  </span>
                </div>
              </div>

              <div className="description-instructions">
                <h2>2. {t("img_instruction_2")}</h2>
                <textarea
                  placeholder={t("img_placeholder")}
                  value={prompt}
                  onChange={handlePromptChange}
                  disabled={loading}
                ></textarea>
              </div>

              <div className="button-section">
                <button
                  className="btn-classify"
                  onClick={handleFirstButtonClick}
                  disabled={loading}
                >
                  {t("btn_get_ranking")}
                </button>
              </div>

              {/* <div className="divider-section">
                        <div className="divider"></div>
                        <p>O</p>
                        <div className="divider"></div>
                    </div>

                    <div className="upload-section">
                        <div className="upload-title">
                            <h2>3. Sube un documento en PDF (Opcional)</h2>
                            <p>También puedes subir un archivo en formato PDF, como una factura comercial o una ficha técnica, que contenga descripciones y/o imágenes de los productos que deseas clasificar.</p>
                        </div>

                        <div className="upload-button">
                            <input type="file" id="pdfUpload" accept=".pdf" onChange={handleFileChange} style={{ display: 'none' }} disabled={loading}/>
                            <label htmlFor="pdfUpload" className="btn">
                                <img src={upload_docs} alt="Icono de Subir" className="upload-icon" /> Subir un archivo
                            </label>
                        </div>
                    </div>
                    <p>Archivo a analizar: {file?.name}</p>
                    <div className="button-section">
                        <button className="btn-classify" onClick={handleSecondButtonClick} disabled={loading}>Obtener Clasificación</button>
                    </div> */}
              <br />

              {response && <h2 id="result">⬇️ {t("results")}</h2>}
              {/* {loading && <p>{t("loading_p")}</p>} */}
              <br />
              <ul>
                <ul>
                  {response?.map((item, index) => (
                    <li className="border fs-5 text" key={index}>
                      <div className="d-flex flex-row justify-content-between align-items-end gap-2 w-100">
                        <div className="d-flex flex-row w-50">
                          <span className="d-flex flex-row gap-2">
                            <strong>{t("result_ranking")}:</strong>
                            <span>{item.fraction.code}</span>
                            {/* Copiar código */}
                            <i
                              className="bi bi-copy ms-3 cursor-pointer"
                              onClick={() => handleCopyCode(item.fraction.code)}
                            ></i>
                          </span>
                        </div>

                        {/* Etiqueta precision */}
                        <div className="d-flex flex-row gap-2 w-50">
                          <span
                            className={`reliability w-50 text-center ${
                              item.overall_confidence >= 0 &&
                              item.overall_confidence <= 9
                                ? "bg-danger"
                                : item.overall_confidence > 9 &&
                                  item.overall_confidence <= 49
                                ? "bg-warning-subtle"
                                : item.overall_confidence > 49 &&
                                  item.overall_confidence <= 69
                                ? "bg-warning"
                                : item.overall_confidence > 69 &&
                                  item.overall_confidence <= 91
                                ? "bg-success"
                                : item.overall_confidence > 91 &&
                                  item.overall_confidence <= 100
                                ? "bg-info"
                                : ""
                            }`}
                          >
                            {item.overall_confidence >= 0 &&
                            item.overall_confidence <= 9
                              ? `${item.overall_confidence}% Sin resultado`
                              : item.overall_confidence > 9 &&
                                item.overall_confidence <= 49
                              ? `${item.overall_confidence}% Baja confianza`
                              : item.overall_confidence > 49 &&
                                item.overall_confidence <= 69
                              ? `${item.overall_confidence}% Confianza media`
                              : item.overall_confidence > 69 &&
                                item.overall_confidence <= 91
                              ? `${item.overall_confidence}% Confianza alta`
                              : item.overall_confidence > 91 &&
                                item.overall_confidence <= 100
                              ? `${item.overall_confidence}% Muy alta confianza`
                              : ""}
                          </span>

                          <InputModalAddFractionToCustomer
                            description={item.fraction.name}
                            tariffClassification={item.fraction.code}
                            imageUrl={imageUrl}
                          />
                          {/* Aquí va un popup */}
                        </div>
                        {/* Etiqueta precision */}
                      </div>
                      {/* Description */}
                      <span className="d-flex flex-row gap-2 mt-3">
                        <p>
                          <strong className="w-auto text-nowrap">
                            {t("result_name")}:
                          </strong>
                        </p>
                        <p className="w-100">{item.fraction.name}</p>
                      </span>
                      {/* Description */}
                      <section className="d-flex flex-row w-100 pt-3 font-gray-strong strong">
                        <span className="w-50">Sección</span>
                        <span className="w-100">Descripción</span>
                      </section>
                      <hr className="my-1" />
                      {/* Sección partidas */}
                      <section className="d-flex flex-column column-gap">
                        <div className="d-flex flex-row column-gap align-items-center">
                          <span className="d-flex flex-row column-gap w-50">
                            <div className="d-flex flex-column w-50">
                              <p className="fs-5 text">{item.chapter.code}</p>
                              <p className="fw-semibold text-gray fs-5 text">
                                Capítulo
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center w-50"></div>
                          </span>
                          <span className="w-100 fs-6">
                            {item.fraction.name}
                          </span>
                        </div>
                        <hr className="my-1" />
                        <div className="d-flex flex-row column-gap align-items-center">
                          <span className="d-flex flex-row column-gap w-50">
                            <div className="d-flex flex-column w-50">
                              <p className="fs-5 text">{item.heading.code}</p>
                              <p className="fw-semibold text-gray fs-5 text">
                                Partida
                              </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-center w-50">
                              {showNearly === index && partida === "heading" ? (
                                <i
                                  className="bi bi-chevron-contract"
                                  onClick={() =>
                                    toggleShowNearly(index, "heading")
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-chevron-expand"
                                  onClick={() =>
                                    toggleShowNearly(index, "heading")
                                  }
                                ></i>
                              )}
                            </div>
                          </span>
                          <span className="w-100 fs-6">
                            {item.heading.name}
                          </span>
                        </div>
                        {/* Sección nearly */}
                        <div
                          className={`${
                            showNearly === index && partida === "heading"
                              ? "d-block"
                              : "d-none"
                          } w-100`}
                        >
                          <TariffNearly
                            tariff={item.fraction.code}
                            nearly="partida"
                            nearlyRef={item.heading.code}
                          />
                        </div>
                        {/* Sección nearly */}
                        <hr className="my-1" />
                        <div className="d-flex flex-row column-gap align-items-center">
                          <span className="d-flex flex-row column-gap w-50">
                            <div className="d-flex flex-column w-50">
                              <p className="fs-5 text">
                                {item.subheading.code}
                              </p>
                              <p className="fw-semibold text-gray fs-5 text">
                                Subpartida
                              </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-center w-50">
                              {showNearlySubheading === index &&
                              subPartida === "subheading" ? (
                                <i
                                  className="bi bi-chevron-contract"
                                  onClick={() =>
                                    toggleShowNearlySubheading(
                                      index,
                                      "subheading"
                                    )
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-chevron-expand"
                                  onClick={() =>
                                    toggleShowNearlySubheading(
                                      index,
                                      "subheading"
                                    )
                                  }
                                ></i>
                              )}
                            </div>
                          </span>
                          <span className="w-100 fs-6">
                            {item.subheading.name}
                          </span>
                        </div>
                        {/* Sección nearly */}
                        <div
                          className={`${
                            showNearlySubheading === index &&
                            subPartida === "subheading"
                              ? "d-block"
                              : "d-none"
                          } w-100`}
                        >
                          <TariffNearly
                            tariff={item.fraction.code}
                            nearly="subpartida"
                            nearlyRef={item.subheading.code}
                          />
                        </div>
                        {/* Sección nearly */}
                        <hr className="my-1" />

                        <div className="d-flex flex-row column-gap align-items-center">
                          <span className="d-flex flex-row column-gap w-50">
                            <div className="d-flex flex-column w-50">
                              <p className="fs-5 text">{item.fraction.code}</p>
                              <p className="fw-semibold text-gray fs-5 text">
                                Fracción
                              </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-center w-50">
                              {showNearlyFraction === index &&
                              fraction === "fraction" ? (
                                <i
                                  className="bi bi-chevron-contract"
                                  onClick={() =>
                                    toggleShowNearlyFraction(index, "fraction")
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-chevron-expand"
                                  onClick={() =>
                                    toggleShowNearlyFraction(index, "fraction")
                                  }
                                ></i>
                              )}
                            </div>
                          </span>
                          <span className="w-100 fs-6">
                            {item.fraction.name}
                          </span>
                        </div>
                      </section>
                      {/* Sección nearly */}
                      <div
                        className={`${
                          showNearlyFraction === index &&
                          fraction === "fraction"
                            ? "d-block"
                            : "d-none"
                        } w-100`}
                      >
                        <TariffNearly
                          tariff={item.fraction.code}
                          nearly="fracciones"
                          nearlyRef={item.fraction.code}
                        />
                      </div>
                      {/* Sección nearly */}
                      <hr className="my-1" />

                      {/* Sección noms =>*/}
                      <TariffNoms tariff={item.fraction.code} />

                      {/* Secci+on likes */}
                      <section className="d-flex flex-row justify-content-between align-items-end w-100">
                        <div className="d-flex flex-row">
                          <span className="m-2 cursor-pointer">
                            <i className="bi bi-hand-thumbs-up"></i>
                          </span>
                          <span className="m-2 cursor-pointer">
                            <i className="bi bi-hand-thumbs-down"></i>
                          </span>
                        </div>
                        <button
                          className="btn-camtom-rounded"
                          onClick={() =>
                            handleRedirectToQuoter(item.fraction.code)
                          }
                        >
                          {t("quote_customs_clearance")}
                        </button>
                      </section>
                      {/* Secci+on likes */}
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffPro;
