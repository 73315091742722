import React, { useEffect, useState } from "react";
import getServices from "../../../../services/getCompanyServices";
import "./Products.css";
import { useTranslation } from "react-i18next";

const ProductInfoView = ({ productInfo, onClose }) => {
  const [productData, setProductData] = useState();
  const { t } = useTranslation(); // i18n // traduccion

  const getProductInfo = async () => {
    const endpoint = `/api/product/by-productId/${productInfo}`;
    const service = new getServices();

    try {
      await service.fetchAllWithTradingCompanyId(
        endpoint,
        productInfo.tradingCompanyId
      );
      const response = service.getResponse();

      setProductData(response.product);

      // console.log(response, "info productos");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, []);

  return (
    <div className="products w-100 h-100 d-flex flex-column p-5 gap-4 position-relative">
      {/* Título + icno "volver" */}
      <div className="d-flex flex-row align-items-center justify-content-start">
        {" "}
        <i
          className="bi bi-arrow-left fs-2 me-4 cursor"
          onClick={onClose}
        ></i>{" "}
        <h1>{t("product_info")}</h1>
      </div>
      {/*  */}
      <div className="flex mx-3 my-3">
        <div className="d-flex flex-row w-100 mt-3">
          <section className="w-50">
            <h5>{t("fraction")}</h5>
            <p>{productData?.tariffClassification}</p>
          </section>
          <section className="w-50">
            <h5>{t("barcode")}</h5>
            {productData?.barCode ? (
              <p>{productData?.barCode}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
        </div>

        <h5>{t("description")}</h5>
        {productData?.descriptionEs ? (
          <p>{productData?.descriptionEs}</p>
        ) : (
          <p>{t("no_products")}</p>
        )}
        <h5>{t("image")}</h5>
        {productData?.imagesLink.length > 0 ? (
          <div className="d-flex flex-wrap my-4 gap-2">
            {productData?.imagesLink?.map((el, index) => (
              <img
                src={el}
                alt={el}
                key={index}
                className="w-25 border rounded"
              />
            ))}
          </div>
        ) : (
          <p style={{ color: "red" }}>{t("no_products")}</p>
        )}

        <h5>{t("classification_source")}</h5>
        {productData?.tariffClassificationStatus ? (
          <p>{productData?.tariffClassificationStatus}</p>
        ) : (
          <p>{t("no_products")}</p>
        )}

        <div className="d-flex flex-row w-100 mt-3">
          <section className="w-50">
            <h5>NOMs</h5>
            {productData?.NOMs.length > 0 ? (
              productData?.NOMs?.map((el, index) => <p>{el}</p>)
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
          <section className="w-50">
            <h5>NICO</h5>
            {productData?.NICO ? (
              <p>{productData?.NICO}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
        </div>

        <div className="d-flex flex-row w-100 mt-3">
          <section className="w-50">
            <h5>{t("brand")}</h5>
            {productData?.marca ? (
              <p>{productData?.marca}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
          <section className="w-50">
            <h5>{t("model")}</h5>
            {productData?.modelo ? (
              <p>{productData?.modelo}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
        </div>

        <div className="d-flex flex-row w-100 mt-3">
          <section className="w-50">
            <h5>{t("country_of_origin")}</h5>
            {productData?.pais ? (
              <p>{productData?.pais}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
          <section className="w-50">
            <h5>{t("tag")}</h5>
            {productData?.etiqueta ? (
              <p>{productData?.etiqueta}</p>
            ) : (
              <p>{t("no_products")}</p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoView;
