// Vamos a manejar todas las peticiones get con esta clase
import { store } from "../redux/store";
const URL = process.env.REACT_APP_serverURL;
const URL_TARIFF = process.env.REACT_APP_serverURLtariff;

class getServices {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }

  async fetchAll(endpoint) {
    try {
      const res = await fetch(`${URL}${endpoint}`);
      const json = await res.json();

      this.response = json;
      // return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async fetchAllWithcustomsAgencyId(endpoint) {
    const state = store.getState(); // Estado de redux
    const customsAgencyId = state.agent.customsAgencyId;
    try {
      const headers = {
        customsAgencyId,
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const res = await fetch(`${URL}${endpoint}`, options);
      const json = await res.json();

      this.response = json;
      // console.log(json, "respuesta get services");
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  // Para pasar el tradingComapanyId por el header
  async fetchAllWithTradingCompanyId(endpoint, tradingCompanyId) {
    // console.log(tradingCompanyId, "id compañia desde servicio");
    try {
      const headers = {
        tradingCompanyId,
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const res = await fetch(`${URL}${endpoint}`, options);
      const json = await res.json();

      this.response = json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  // Para pasar el tradingComapanyId por el header desde el state
  async fetchAllWithTradingCompanyIdAndTestingURL(endpoint) {
    const state = store.getState(); // Estado de redux
    const tradingCompanyId = state.user.tradingCompanyId;
    // console.log(tradingCompanyId, endpoint);
    try {
      const headers = {
        tradingCompanyId,
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const res = await fetch(`${URL}${endpoint}`, options);
      const json = await res.json();

      this.response = json;
      // return json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async fetchAllWithUrlTariff(endpoint, tariff) {
    const baseUrl = URL_TARIFF; // URL base de la API
    const queryParams = new URLSearchParams({
      fraccion: tariff.toString(),
      fraccion_nico: tariff.toString(),
      importacion_o_exportacion: "importacion",
      pais: "Mexico",
    });

    const url = `${baseUrl}${endpoint}?${queryParams.toString()}`;

    try {
      const response = await fetch(url);
      const res = await response.json();
      // console.log(res, "response");
      this.response = res;
    } catch (error) {
      console.error("Error fetching tariff data:", error);
      throw error;
    }
  }
}

export default getServices;
