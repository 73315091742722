import loaginImage from "../../../../imagesCommon/Vector.svg";
import cloud from "../../../../imagesCommon/cloud.svg";
import { useTranslation } from "react-i18next";

function LoadingCamtom() {
  const { t } = useTranslation(); // i18n // traduccion
  return (
    <div class="d-flex flex-column align-items-center justify-content-center gap-4 overflow-hidden">
      <div class="position-relative up">
        <img
          src={loaginImage}
          alt=""
          className="w-100 animation-loading pt-5 plane"
        />
        <img src={cloud} alt="" className="position-ab w-50 cloud-front" />
        <img src={cloud} alt="" className="position-ab w-25 cloud-back" />
      </div>
      <h4 className="w-75 text-center">{t("loading_message")}</h4>
    </div>
  );
}

export default LoadingCamtom;
