import React, { useState } from "react";
import "../.././style.css";
import logo from "../../../imagesCommon/camtom-enterprises.svg";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase.js";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/reducers.js";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AcceptCookies from "../../../cookies/AcceptCookies.js";
import logoEmpresa from "../../../imagesCommon/logoEmpresa.png";
import logoRuffo from "./iconos/logoRuffo.png";
import { useForm } from "react-hook-form";

import axios from "axios";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

const serverURL = process.env.REACT_APP_serverURL;

function LoginTradingCompany() {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.username,
        data.password
      );

      const headers = {
        UID: userCredential.user.uid,
      };

      const userIdResponse = await axios.get(
        `${serverURL}/api/login/tradingCompanyEmployee`,
        { headers }
      );

      //QUITAR esta barrabasada cuando se haga el deploy
      const token = userIdResponse.data.tempToken;
      //console.log(token, "token login");
      const email = userIdResponse.data.email;
      const tradingCompanyId = userIdResponse.data.tradingCompanyId;
      const userId = userIdResponse.data.tradingCompanyEmployeeId;

      Cookies.set("tokenUser", token, { expires: 3 });
      Cookies.set("email", email, { expires: 3 });

      // console.log(email, token);

      const user = {
        token,
        email,
        tradingCompanyId,
        userId,
      };
      // console.log(user);

      dispatch(loginUser(user));
      toast("¡Bienvenido a Camtom!", {
        icon: "🚀",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setTimeout(() => {
        navigate(`/user-dashboard`);
      }, 350);
    } catch (error) {
      toast("Credenciales incorrectas", {
        icon: "❌ ",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      console.log(error);
    }
  };

  const logoSrc = window.location.href.includes("ruffo") ? logoRuffo : logo;

  return (
    <div className="login-container">
      {/* SECTION IMG DESTACADA */}

      <section class="section-logo">
        <img src={logoEmpresa} alt="logoAgencia" />
      </section>
      {/* SECTION IMG DESTACADA */}

      {/* SECTION LOGIN FORMULARIO */}

      <section class="section-login d-flex align-items-center p-5 padding-left-right-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 d-flex flex-column gap-2"
        >
          {/* LOGO-ENCABEZADO */}
          <a href="/" className="d-flex flex-column mb-5 text-color-camtom">
            <img className="logo-camtom" src={logoSrc} alt="Logo" />
          </a>

          <h1 className="my-5">Iniciar Sesión</h1>
          {/* LOGO-ENCABEZADO */}

          {/* IMPUT */}
          <div class="d-flex flex-column pb-4">
            <label htmlFor="username" className="pb-4 fw-normal fs-5">
              Correo electrónico:{/* <b>{t("login_email")}</b> */}
            </label>
            <span class="input-border d-flex flex-row">
              <i className="bi bi-person fs-3 me-2"></i>
              <input
                type="text"
                id="username"
                // onChange={(e) => setUsername(e.target.value)}
                className="input-login p-2 w-100"
                {...register("username", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </span>
            {errors.username && (
              <span className="span-error">{errors.username.message}</span>
            )}
          </div>
          {/* IMPUT */}
          <div class="d-flex flex-column pb-4">
            <label htmlFor="password" className="pb-4 fw-normal fs-5">
              Contraseña:{/* <b>{t("login_email")}</b> */}
            </label>
            <span class="input-border d-flex flex-row">
              <i className="bi bi-lock fs-3 me-2"></i>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                // onChange={(e) => setPassword(e.target.value)}
                className="input-login p-2 w-100"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
              {showPassword ? (
                <i
                  className="bi bi-eye-slash lh-3"
                  onClick={handleShowPassword}
                ></i>
              ) : (
                <i className="bi bi-eye lh-3" onClick={handleShowPassword}></i>
              )}
            </span>
            {errors.password && (
              <span className="span-error">{errors.password.message}</span>
            )}
          </div>
          <div className="d-flex flex-row align-items-baseline w-100">
            <button type="submit" className="btn-camtom p-3 w-25 me-5">
              {t("login_btn")}
            </button>
            <p className="text-center w-50">
              <a
                href="https://www.camtomx.com/forgot-password"
                style={{ color: "#004A7C" }}
              >
                {t("login_question")}
              </a>
            </p>
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          <br />
        </form>
        <AcceptCookies />
      </section>
    </div>
    // <div className="login-wrapper login-background position-relative">
    //   <div className="form-signup-wrapper">
    //     <form onSubmit={handleSubmit}>
    //       {/*          <div className={`toggle-button-login ${isOn ? 'on' : ''}`}
    //                 style={{justifyContent: 'center'}}
    //                 onClick={toggleButton}>
    //                     <div className="toggle-indicator-login">
    //                         <div className="toggle-text-login">{!isOn ? 'Empresa' : 'Agente'}</div>
    //                     </div>
    //                     <div className="toggle-label-login">{'Empresa                Agente'}</div>

    //       </div> */}

    //       <a href="/">
    //         <img className="logo-login" src={logo} alt="Logo" />
    //       </a>

    //       <label>
    //         <p>
    //           <b>{t("login_email")}</b>
    //         </p>
    //         <input type="text" onChange={(e) => setUsername(e.target.value)} />
    //       </label>
    //       <label>
    //         <p>
    //           <b>{t("login_pass")}</b>
    //         </p>
    //         {/* Aquí ponemos un div para poner los iconos de mostrar contraseña */}
    //         <div className="position-relative">
    //           <input
    //             type={showPassword ? "text" : "password"}
    //             onChange={(e) => setPassword(e.target.value)}
    //           />
    //           {showPassword ? (
    //             <i
    //               className="bi bi-eye-slash lh-3"
    //               onClick={handleShowPassword}
    //             ></i>
    //           ) : (
    //             <i className="bi bi-eye lh-3" onClick={handleShowPassword}></i>
    //           )}
    //         </div>
    //       </label>
    //       <div>
    //         <button type="submit">{t("login_btn")}</button>
    //       </div>
    //       {error && <div className="error">{error}</div>}
    //       <br />
    //       <p className="text-center">
    //         <a
    //           href="https://www.camtomx.com/forgot-password"
    //           style={{ color: "#004A7C" }}
    //         >
    //           {t("login_question")}
    //         </a>
    //       </p>
    //     </form>
    //     <div className="signup-wrapper"></div>
    //   </div>
    //   <br />
    //   <br />
    //   <br />
    //   <br />
    //   <AcceptCookies />
    // </div>
  );
}

export default LoginTradingCompany;
