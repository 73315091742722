import { Routes, Route, Navigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//dependencias mapa
import "react-calendar/dist/Calendar.css";
import Mapbox from "react-map-gl";
import axios from "axios";

//dependencias calendario
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import Sidebar from "./Sidebar";
// import QuoteForm from './QuoteForm';
// import ListReports from './ListReports';
// import ListProducts from './ListProducts';
// import Report from './ReportPrueba'
// import Transactions from './Transactions';
// import Shipments from './Shipments';
// import TermsConditions from './TermsConditions';
// import SuccessQuote from './SucessQuote';
// import Help from './Help';
// import Operations from './Operations';
// import EditQuote from './EditQuote';
// import DocumentsPerOperation from './Operation/DocumentsPerOperation';
// import DocumentsPerOperation2 from './DocumentsPerOperation';
// import AgentManager from './AgentManager';
// import SeeOperation from './SeeOperation';
// import NewOperation from './Operation/NewOperation';
// import OperationList from './Operation/OperationList';
// import EditOperationUser from './Operation/EditOperation';
// import InfoOperationUser from './Operation/InfoOperationUser';

import "./DashboardMVP.css";
import products_added from "../../images/icons-mvp/products_added.svg";
import completed_op from "../../images/icons-mvp/completed_op.svg";
import approved_op from "../../images/icons-mvp/approved_op.svg";
// import NewOperationC from './Operation/NewOperation copy';
// import InfoOperationUserC from './Operation/InfoOperationUserC';
// import DocumentsPerOperationC from './Operation/DocumentsPerOperationC';
// import EditOperationUserC from './Operation/EditOperationC';
// import QuoteFormC from './QuoteFormC';

import TariffProImg from "./TariffPro/TariffProImg";
import TariffProPdf from "./TariffPro/TariffProPdf";
import TariffProText from "./TariffPro/TariffProText";
import TariffProXlsx from "./TariffPro/TariffProXlsx";
import Cookies from "js-cookie";
import Products from "./Products/Products";
import ListQuoterView from "./quoter/ListQuoterView";
import AddQuoterView from "./quoter/AddQuoterView";
import Digiter from "../../digiter/Digiter";
import TariffPro from "./TariffPro/TariffPro";
import { useTranslation } from "react-i18next";
import BillingCompany from "../billing/BillingCompany";
const serverURL = process.env.REACT_APP_serverURL;

function UserDashboard() {
  const token = useSelector((state) => state.user.token);

  const PrivateRouteAgent = ({ children }) => {
    return token ? children : <Navigate to="/login/trading-company" replace />;
  };

  return (
    <div className="UserDashboard">
      <Sidebar />
      <Routes>
        <Route path="/" element={<DashboardPrev />} />
        <Route path="/new-simulations" element={<AddQuoterView />} />
        <Route path="/my-simulations" element={<ListQuoterView />} />
        <Route path="/digiter" element={<Digiter />} />
        <Route path="/billing" element={<BillingCompany />} />
        <Route
          path="/tariff-pro-clasifications-database"
          element={<Products />}
        />{" "}
        <Route
          path="/tariff-pro"
          element={
            <PrivateRouteAgent>
              <TariffPro />
            </PrivateRouteAgent>
          }
        >
          {/* SUBRUTAS DE TARIFF */}
          <Route path="tariff-pro-img" element={<TariffProImg />} />
          <Route path="tariff-pro-pdf" element={<TariffProPdf />} />
          <Route path="tariff-pro-text" element={<TariffProText />} />
          <Route path="tariff-pro-xlsx" element={<TariffProXlsx />} />
        </Route>
        {/* <Route path="/quotation/" element={<QuoteFormC/>} />
        <Route path="/operations" element={<OperationList/>} />
        <Route path="/operation/documents" element={<DocumentsPerOperationC/>} />
        <Route path="/reports/:userId" element={<ListReports/>} />
        <Route path="/report/:reportId/:userId" element={<Report/>} />
        <Route path="/shipments" element={<Shipments/>} />
        <Route path="/help" element={<Help/>} />
        <Route path="/terms-conditions" element={<TermsConditions/>} />
        <Route path="/success-quote" element={<SuccessQuote/>} />
        <Route path="/editquote/:reportId" element={<EditQuote/>} />
        <Route path="/agent-manager" element={<AgentManager/>} />
        <Route path="/add-product" element={<AddProductForm/>} />
        <Route path="/product-manager" element={<ProductManager/>} />
        <Route path="/edit-product/:productId" element={<EditProduct/>} />
        <Route path="/info-product" element={<InfoProduct/>} />
        <Route path="/new-operation" element={<NewOperationC/>} />
        <Route path="/edit-operation/:operationId" element={<EditOperationUserC/>} />
        <Route path="/info-operation/:operationId" element={<InfoOperationUserC/>} />
        <Route path="/operation/:operationId/documents" element={<DocumentsPerOperation/>} />  */}
      </Routes>
    </div>
  );
}

function DashboardPrev() {
  const { t } = useTranslation(); // i18n // traduccion
  const email = Cookies.get("email");

  const userId = useSelector((state) => state.user.userId);
  // const userEmail = useSelector((state) => state.user.email);
  const [reports, setReports] = useState([]);

  useEffect(() => {}, []);

  return (
    <div className="DashboardAgent">
      <div className="main-content">
        <h1>
          ¡{t("home_welcome")} {email}!
        </h1>

        <div className="card-container">
          <Link to={"/user-dashboard/tariff-pro/tariff-pro-img"}>
            <div className="card">
              <i className="bi bi-file-image me-3 fs-1 text-dark"></i>
              <p>{t("home_img")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-pdf">
            <div className="card">
              <i className="bi bi-filetype-pdf me-3 fs-1 text-dark"></i>
              <p>{t("home_pdf")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-text">
            <div className="card">
              <i className="bi bi-justify-left me-3 fs-1 text-dark"></i>
              <p>{t("home_txt")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          <Link to="/user-dashboard/tariff-pro/tariff-pro-xlsx">
            <div className="card">
              <i className="bi bi-filetype-xlsx me-3 fs-1 text-dark"></i>
              <p>{t("home_xlsx")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link>
          {/* <Link to="/user-dashboard/tariff-pro/tariff-pro-url">
            <div className="card">
              <i className="bi bi-link-45deg me-3 fs-1 text-dark"></i>
              <p>{t("home_url")}</p>
              <i className="bi bi-arrow-right-circle fs-1 text-dark"></i>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
