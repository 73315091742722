import React, { useState } from "react";
import "./styles/InputModals.css";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import postServices from "../services/PostAgencyServices";
// import getServices from "../services/getServices";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "5%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%)",
    width: "55%",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function InputModalAddCompanies({ fetchCompanyData }) {
  // props (get companies, get products)
  let subtitle; // Para el modal (no borrar)
  const [modalIsOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(); // i18n // traduccion

  //Funciones del form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // console.log(companiesData);

  const onSubmit = async (data) => {
    const endpoint = "/api/tradingCompany/create";
    const dataToPost = {
      email: data.email,
      UID: data.UID,
      commercialName: data.comercialName,
      legalData: {
        businessName: data.businessName,
        taxID: data.taxID,
        // legalRepresentativeName: "John Doe",
        // legalContactNumber: "1234567890",
      },
      legalAddress: {
        street: data.street,
        intNumber: data.intNumber,
        extNumber: data.extNumber,
        city: data.city,
        neighborhood: data.neighborhood,
        state: data.state,
        zip: data.zip,
        country: data.country,
      },
    };
    // Instanciando el servicio
    const services = new postServices();
    // console.log(dataToPost, data, "data");
    try {
      await services.postDataWithCustomAgencyId(
        endpoint,
        dataToPost,
        data.cliente
      ); //Enviando datos al servicio
      const response = services.getResponse(); // Obteniendo la respuesta del servicio

      // console.log(response);
      fetchCompanyData(); // Volver a traer los datos para actualizar la tabla
      reset();
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  // Funciones del modal
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="inputModal">
      <button
        onClick={openModal}
        className="btn-primary d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-plus fs-4"></i>
        <span>{t("add_new_customer")}</span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal} //Close btn action
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2
          ref={(_subtitle) => (subtitle = _subtitle)}
          className="text-center py-2"
        >
          {t("add_new_customer")}
        </h2>

        <button onClick={closeModal} className="btn-close-modal">
          <i className="bi bi-x fs-4"></i>
        </button>

        {/* Empieza el formulario */}
        <form className="d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="my-4 font-gray-light">
            {t("form_add_customer_subtitle")}
          </h3>
          <div className="mb-3">
            <label htmlFor="comercialName" className="form-label">
              {t("form_importer_name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="comercialName"
              placeholder={t("form_importer_name")}
              {...register("comercialName", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.comercialName && (
              <span className="span-error">{errors.comercialName.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="businessName" className="form-label">
              {t("form_company_business_name")}
            </label>
            <input
              type="text"
              className="form-control"
              id="businessName"
              placeholder={t("form_company_business_name")}
              {...register("businessName", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.businessName && (
              <span className="span-error">{errors.businessName.message}</span>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              {...register("email", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.email && (
              <span className="span-error">{errors.email.message}</span>
            )}
          </div>

          {/* <div className="mb-3">
            <label htmlFor="UID" className="form-label">
              UID:
            </label>
            <input
              type="text"
              className="form-control"
              id="UID"
              placeholder="UID"
              {...register("UID", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            />
            {errors.UID && (
              <span className="span-error">{errors.UID.message}</span>
            )}
          </div> */}

          <div className="mb-3">
            <label htmlFor="taxID" className="form-label">
              {t("form_RFC_customer")}
            </label>
            <input
              type="text"
              className="form-control"
              id="taxID"
              placeholder={t("form_RFC_customer")}
              {...register("taxID", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            ></input>

            {errors.taxID && (
              <span className="span-error">{errors.taxID.message}</span>
            )}
          </div>
          <h3 className="my-4 font-gray-light">{t("form_address")}</h3>

          <div className="mb-3">
            <label htmlFor="street" className="form-label">
              {t("form_street")}
            </label>
            <input
              type="text"
              className="form-control"
              id="street"
              placeholder={t("form_street")}
              {...register("street", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            ></input>

            {errors.street && (
              <span className="span-error">{errors.street.message}</span>
            )}
          </div>
          <div class="d-flex flex-row gap-2">
            <div className="mb-3 w-50">
              <label htmlFor="extNumber" className="form-label">
                {t("form_n_ext")}
              </label>
              <input
                type="text"
                className="form-control"
                id="extNumber"
                placeholder={t("form_n_ext")}
                {...register("extNumber", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              ></input>

              {errors.extNumber && (
                <span className="span-error">{errors.extNumber.message}</span>
              )}
            </div>

            <div className="mb-3 w-50">
              <label htmlFor="intNumber" className="form-label">
                {t("form_n_int")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("form_n_int")}
                id="intNumber"
                {...register("intNumber", {})}
              ></input>
            </div>
          </div>
          <div class="d-flex flex-row gap-2">
            <div className="mb-3 w-50">
              <label htmlFor="neighborhood" className="form-label">
                {t("form_neighborhood")}
              </label>
              <input
                type="text"
                className="form-control"
                id="neighborhood"
                placeholder={t("form_neighborhood")}
                {...register("neighborhood", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              ></input>

              {errors.neighborhood && (
                <span className="span-error">
                  {errors.neighborhood.message}
                </span>
              )}
            </div>
            <div className="mb-3 w-50">
              <label htmlFor="zip" className="form-label">
                {t("form_postal_code")}
              </label>
              <input
                type="text"
                className="form-control"
                id="zip"
                placeholder={t("form_postal_code")}
                {...register("zip", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              ></input>

              {errors.zip && (
                <span className="span-error">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div class="d-flex flex-row gap-2">
            <div className="mb-3 w-50">
              <label htmlFor="state" className="form-label">
                {t("form_state")}
              </label>
              <input
                type="text"
                className="form-control"
                id="state"
                placeholder={t("form_state")}
                {...register("state", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              ></input>

              {errors.state && (
                <span className="span-error">{errors.state.message}</span>
              )}
            </div>
            <div className="mb-3 w-50">
              <label htmlFor="city" className="form-label">
                {t("city")}
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder={t("city")}
                {...register("city", {
                  required: {
                    value: true,
                    message: t("required_message"),
                  },
                })}
              ></input>

              {errors.city && (
                <span className="span-error">{errors.city.message}</span>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              {t("form_country")}
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              placeholder={t("form_country")}
              {...register("country", {
                required: {
                  value: true,
                  message: t("required_message"),
                },
              })}
            ></input>

            {errors.country && (
              <span className="span-error">{errors.country.message}</span>
            )}
          </div>

          <button type="submit" className="btn-fill">
            {t("add_new")}
          </button>
        </form>
      </Modal>
    </div>
  );
}
export default InputModalAddCompanies;
