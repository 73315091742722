import { store } from "../redux/store";

// Vamos a manejar todas las peticiones get con esta clase
const URL = process.env.REACT_APP_serverURL;

class putServices {
  constructor() {
    this.response = [];
  }

  getResponse() {
    return this.response;
  }

  async putDataWithHeaders(endpoint, body, companyId) {
    const tradingCompanyId = companyId;
    //console.log(tradingCompanyId);
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        tradingCompanyId,
      },
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(`${URL}${endpoint}`, options);
      // console.log(URL, endpoint);
      const json = await res.json();
      this.response = json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async patchDataWithHeaders(endpoint, body) {
    const state = store.getState(); // Estado de redux
    const customsAgencyId = state.agent.customsAgencyId;

    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        customsAgencyId,
      },
      body: JSON.stringify(body),
    };

    try {
      const res = await fetch(`${URL}${endpoint}`, options);
      console.log(URL, endpoint);
      const json = await res.json();
      this.response = json;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export default putServices;
