// import { persistStore } from 'redux-persist';
// import persistedReducer from "./reducers.js";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers.js";

export const store = configureStore({
  reducer: rootReducer,
});

// export const persistor = persistStore(store);
