import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';

import './AgentUsers.css';

const serverURL = process.env.REACT_APP_serverURL;

 export default function AgentUsers() {
  const [selectedUser, setSelectedUser] = useState(
    JSON.parse(localStorage.getItem('selectedUser')) || null
  );
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem('selectedOption') || 'Borradores'
  );
  
  const agentId = useSelector(state => state.agent.agentId);
  
  const fetchAgentUsers = async () => {
    try {
      const response = await axios.get(`${serverURL}/api/agent/users/get-users?userId=${agentId}`);
      setUsers(response.data.agentUsers);
      console.log(response.data.agentUsers);
    } catch (error) {
      console.error('Error fetching agent users:', error);
      // Handle error scenarios
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    localStorage.setItem('selectedUser', JSON.stringify(user));
  };

  const setSelectedOptionAndSave = (option) => {
    setSelectedOption(option);
    localStorage.setItem('selectedOption', option);
  };

  const openModal = (e) => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
      setIsModalOpen(false);
  };

  useEffect(() => {
    fetchAgentUsers();
  },[]);

  return (
    <div className="agentClients">
      <div className="user-list">
        <h2>Clientes</h2>

        <button
        onClick = {openModal} 
        style={{width:'100%'}}
        className="btn-round">+ Agregar Usuario
        </button>
        {isModalOpen && 
          <FileAddClient
          onClose={closeModal}/>
        }
          {users.map((user) => (
            <div className="user-list-item" 
            key={user.id} 
            onClick={() => handleUserSelect(user)}>

              {selectedUser?._id === user._id ? (
                <div className="selected-user">
                  <h5><b>{user.name}</b></h5>
                  <p>{user.email}</p>
                  <p>RFC: {user.rfc}</p>
                </div>
              ) : (
                <div className="user-preview">
                  <h5><b>{user.name}</b></h5>
                  <p>{user.email}</p>
                  <p>RFC: {user.rfc}</p>
                </div>
              )}
            </div>
          ))}

      </div>
      <div className="user-details">
        {selectedUser ? (
          <div>
            <UserDisplay 
            selectedUser={selectedUser}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOptionAndSave}
            />
          </div>
        ) : (
          <p
          style={{
           justifyContent:'center',
           alignItems:'center',
           display:'flex',
           height:'98%'
          }}
          ><i>Selecciona un usuario para ver sus detalles</i></p>
        )}
      </div>
    </div>
  );
};

const UserDisplay = ({ selectedUser, selectedOption, setSelectedOption }) => {
  const [userOperations, setUserOperations] = useState([]);
  const [displayedOperations, setDisplayedOperations] = useState([]);
  const [selectedOperationType, setSelectedOperationType] = useState('Todo');

  const serverURL = process.env.REACT_APP_serverURL;

  const fetchUserOperations = async () => {
    try {
      const response = await axios.get(`${serverURL}/api/agent/operation/user-operations/${selectedUser._id}`);
      const operations = response.data.filteredOperations;
      setUserOperations(operations);
      if (selectedOption === 'Operaciones aprobadas') {
        setDisplayedOperations(operations.approved);
      } else if (selectedOption === 'Operaciones pendientes') {
        setDisplayedOperations(operations.pending);
      } else if (selectedOption === 'Operaciones completadas') {
        setDisplayedOperations(operations.completed);
      } else if (selectedOption === 'Borradores') {
        setDisplayedOperations(operations.agentDraft);
      }
      return; // Return the fetched operations
    } catch (error) {
      console.error('Error fetching user operations:', error);
    }
  };

  useEffect(() => {
    fetchUserOperations();
  }, [selectedUser]);

  useEffect(() => {
    // Update displayedOperations when selectedOption changes
    if (selectedOption === 'Operaciones aprobadas') {
      setDisplayedOperations(userOperations.approved);
    } else if (selectedOption === 'Operaciones pendientes') {
      setDisplayedOperations(userOperations.pending);
    } else if (selectedOption === 'Operaciones completadas') {
      setDisplayedOperations(userOperations.completed);
    }else if (selectedOption === 'Borradores') {
      setDisplayedOperations(userOperations.agentDraft);
    }
    
  }, [selectedOption]);

  return (
    <div className='agent-user-display'>

      <h1>Información de la empresa</h1>

      <div className='info-clients-header-container'>
        <div className="text">
         
          <h4>{selectedUser.name}</h4>
          <h4>RFC: {selectedUser.rfc}</h4>
          <small>Datos del responsable:</small>
          <h4>{selectedUser.legalRepresentative.name}</h4>
          <h4>{selectedUser.legalRepresentative.position}</h4>
        </div>
        
        <button>
          <a 
          style={{textDecoration:'none', color:'white'}}
          href={`/agent-dashboard/${selectedUser._id}/new-operation`}>
          + Agregar <br />nueva operación
          </a>
        </button>

      </div>

      <div className='info-clients-docs-container'>
            <div className='ag-options-operation type-of-operation-duetime'>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Borradores' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('Borradores')}
                >
                Borradores ({userOperations?.agentDraft?.length})
                </p>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Operaciones pendientes' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('Operaciones pendientes')}
                >
                Operaciones pendientes ({userOperations?.pending?.length})
                </p>
                <p
                className={`info-clients-option-documents  ${selectedOption === 'Operaciones aprobadas' ? 'selected' : ''}`}
                value='Operaciones aprobadas'
                onClick={() => setSelectedOption('Operaciones aprobadas')}
                >
                Operaciones aprobadas ({userOperations?.approved?.length})
                </p>
                <p
                className={`info-clients-option-documents ${selectedOption === 'Operaciones completadas' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('Operaciones completadas')}
                >
                Operaciones completadas ({userOperations?.completed?.length})
                </p>
                <p 
                className='option-filter'
                >
                {/*<button class="filter-btn">
                  Filtrar
                </button>*/}
                </p>
                
            </div>
            {/*
            <div className='filter-by-operation-type'>
                <button onClick={() => setSelectedOperationType('Todo')}>Todo</button>
                <button onClick={() => setSelectedOperationType('Importacion')}>Importacion</button>
                <button onClick={() => setSelectedOperationType('Exportacion')}>Exportacion</button>
            </div>*/}

            {displayedOperations?.length > 0 ? displayedOperations?.map((operation) => (
             <div className='operation-card'>
              <Link to={`/agent-dashboard/${selectedUser._id}/info-operation/${operation._id}`}>
                  <small><b>{operation.importExport}</b>
                    </small>
                <h4>{operation.products[0]?.productName} - {operation.products[0]?.tariffClassification}</h4>
                <p>{`
                  Desde ${operation.transport.origin.cityOrigin} en 
                  ${operation.transport.origin.countryOrigin}  
                  a ${operation.transport.destination.cityDestination} en 
                  ${operation.transport.destination.countryDestination}
                  `}
                </p>
                <p>{operation.incoterm}</p>

              </Link>
            </div>
            ))
              : <p><i>{`${selectedOption}`} no existentes para este usuario</i></p>
          }
        
        </div>
    </div>
    
  );
}

const FileAddClient = ({onClose}) => {
  const [email, setEmail] = useState('');
  const agentId = useSelector(state => state.agent.agentId);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInvite = async () => {
    // Simulate sending the invitation to the backend
    let invite;
    try {
      const inviteBody = {
        userEmail: email,
        agentId: agentId
      }

      invite = await axios.post(`${serverURL}/api/agent/users/invite-user/`, inviteBody);
      console.log(invite);

      alert(invite.data.message);
      onClose();
    } catch (error) {
      console.error('Error inviting user:', error);
        alert(error.response.data.message);
    }

  };

  return (
      <div className='fileUploadModal-main'>
          <div className="modal-content">
              <h2 className='text-center'>Agregar nuevo cliente</h2>
              <br/>
              <p>Trabajar con tus clientes a través de Camtom Agente es muy sencillo:</p>
              <p>1. Envía una invitación vía email para que se registren en la plataforma.</p>
              <button 
              style={{marginTop:'1vw',
              marginBottom:'2vw',
              width:'100%'
            }}
              className='btn-transparent'><a href='mailto:?cc=hello@camtomx.com&subject=%C2%A1%C3%9Anete%20a%20Camtom!&body=**No%20te%20olvides%20de%20agregar%20el%20destinatario**%0A%0AHola%20%5BNombre%5D%2C%0A%0AEstamos%20trabajando%20con%20Camtom%2C%20una%20plataforma%20log%C3%ADstica%20que%20har%C3%A1%20tus%20operaciones%20m%C3%A1s%20f%C3%A1ciles%20y%20eficientes.%20%C2%A1Reg%C3%ADstrate%20para%20comenzar%20a%20colaborar%20juntos!%0A%0Ahttps%3A%2F%2Fwww.camtomx.com%2Finvite%0A%0A%0A%0A'>Enviar invitación a Camtom</a></button>
              <p>2. Una vez registrado, ingresa su dirección de email para comenzar a trabajar juntos.</p>
                          <input
                              className=''
                              type="text"
                              name="email"
                              placeholder="nombre@empresa.com"
                              value={email}
                              onChange={handleEmailChange}
                          />
                            
                          <button 
                          style={{
                            marginTop:'3vw',
                          }}
                          className='btn-square' type='button' onClick={handleInvite}>Agregar a mis clientes</button>

                          <a 
                          className='text-center close' 
                          style={{cursor:'pointer'}}
                          onClick={onClose}>Cancelar</a>
          </div>
      </div>
  );
};

