import { useEffect, useState } from "react";
import getNearlyService from "../../../services/getNearlyServices.js";
const TariffNearly = ({ tariff, nearly, nearlyRef }) => {
  const [responseNearly, setResponseNearly] = useState(null);
  const [nearlyProp, setNearlyProp] = useState("");

  useEffect(() => {
    setNearlyProp(`${nearly}_nearly`);
  }, [nearly, nearlyProp]);

  useEffect(() => {
    const fetchNearly = async () => {
      const service = new getNearlyService();

      try {
        await service.getNearly(tariff);
        const response = service.getResponse();

        // Scando indice de referencia
        const referenceIndex = response[nearlyProp].findIndex(
          (el) => el.fraccion && el.fraccion.includes(nearlyRef)
        );
        // Referenciando las posiciones
        let mayor = Number(referenceIndex) + 3;
        let menor = Number(referenceIndex) - 3;

        // Sacando los 2 mas cercanos
        const filtered = response[nearlyProp].filter(
          (el, index) =>
            // el.fraction.indexOf(nearlyRef) < menor &&
            // el.fraction.indexOf(nearlyRef) > mayor
            index < mayor && index > menor
        );

        setResponseNearly(filtered);
      } catch (error) {
        console.error(error);
      }
    };
    // Nos aseguramos de tener el valor nearlyProp antes de llamar a la función
    if (nearlyProp) {
      fetchNearly();
    }
  }, [tariff, nearlyProp, nearlyRef]);

  return (
    <>
      <ul className={`border-nearly rounded w-100 h-10 p-1`}>
        {/* Verificando que ya estén los valores de la respuesta */}
        {responseNearly ? (
          responseNearly.map((el, index) => (
            <li
              className={`m-0 p-0 px-2 d-flex flex-row gap-3 ${
                el.fraccion === nearlyRef ? "border-nearly-focus" : null
              }`}
              key={index}
            >
              <span className="w-auto text-nowrap">{el.fraccion}</span>
              <span className="w-auto">{el.descripcion}</span>
            </li>
          ))
        ) : (
          <i className="bi bi-arrow-clockwise spin"></i>
        )}
      </ul>
    </>
  );
};

export default TariffNearly;
