import React, { useState } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import { useSelector } from "react-redux";

import upload_docs from "../../Agents/iconos/upload_docs.svg";
import check from "../../Agents/iconos/check.svg";
import exit from "../../Agents/iconos/exit.svg";

import "./TariffPro.css";
// import LoadingScreen from "../../../Loading.js";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import LoadingCamtom from "../../../../Modals/LoadingCamtom.js";
import { useNavigate } from "react-router-dom";
import TariffNoms from "../../tariffCommon/TariffNoms.js";
import TariffNearly from "../../tariffCommon/TariffNearly.js";

const TariffPro = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNearly, setShowNearly] = useState(null);
  const [showNearlySubheading, setShowNearlySubheading] = useState(null);
  const [showNearlyFraction, setShowNearlyFraction] = useState(null);
  const [partida, setPartida] = useState(null);
  const [subPartida, setSubPartida] = useState(null);
  const [fraction, setFraction] = useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const serverUrl = process.env.REACT_APP_serverURLtariff;
  // const tokenAgent = useSelector((state) => state.agent?.agent?.token);
  const token = useSelector((state) => state.user.token);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePostToApi = async (url, body) => {
    try {
      console.log(body);
      const response = await axios.post(url, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      // Handle the response as needed
      if (typeof response.data === "string") {
        setResponse(JSON.parse(response.data));
      } else {
        setResponse(response.data);
      }
      console.log(response.data);
    } catch (error) {
      // if (error.response.data.error) {
      //   console.error("Error:", error.response.data.error);
      //   alert("Error: " + error.response.data.error);
      // } else {
      //   alert(t("alert_error"));
      // }

      setLoading(false);
      toast.dismiss(toast.error);

      const errorResponse = error.response?.data?.error || t("alert_error");
      console.log(errorResponse, "variable");

      toast.error(errorResponse, {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleSecondButtonClick = async () => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const apiUrl = `${serverUrl}/api/v2/pdf-file`;
      await handlePostToApi(apiUrl, formData);
    } else {
      alert(t("alert_empty"));
    }
    setLoading(false);
  };
  // Quitar archivo
  const handleExitBtn = () => {
    setFile(null);
  };

  // Copiar código

  const handleCopyCode = (e) => {
    console.log(e);

    try {
      navigator.clipboard.writeText(e);
      // console.log("texto copiado", e);
      // setAlertMessage("¡Texto copiado en el portapapeles!");
      // showAlert(true);
      // setTimeout(() => {
      //   setAlertMessage("");
      // }, 1500);

      toast.error("¡Texto copiado en el portapapeles!", {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (err) {
      // setAlertMessage("Error al copiar el código");

      // showAlert(false);
      // setTimeout(() => {
      //   setAlertMessage("");
      // }, 1500);

      toast.error("Error al copiar el código", {
        icon: "❗❗",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleRedirectToQuoter = (fraction) => {
    navigate(`/user-dashboard/new-simulations`, {
      state: { key: fraction },
    });
    console.warn(fraction);
  };

  const toggleShowNearly = (index, partida) => {
    setShowNearly(showNearly === index ? null : index);
    setPartida(partida);
  };

  const toggleShowNearlySubheading = (index, subheading) => {
    setShowNearlySubheading(showNearlySubheading === index ? null : index);
    setSubPartida(subheading);
  };

  const toggleShowNearlyFraction = (index, fraction) => {
    setShowNearlyFraction(showNearlyFraction === index ? null : index);
    setFraction(fraction);
  };

  return (
    <>
      {loading ? (
        <LoadingCamtom />
      ) : (
        <div className="TariffPro position-relative">
          <div className="main-content">
            <div className="classifier-section">
              <div className="upload-title">
                {/* <h1>{t("pdf_title")}</h1> */}
                {/* <p>También puedes subir un archivo en formato PDF, como una factura comercial o una ficha técnica, que contenga descripciones y/o imágenes de los productos que deseas clasificar.</p> */}
              </div>

              <div className="upload-button">
                <h2>1. {t("pdf_description_1")}</h2>
                <p>{t("pdf_description_1_p")}</p>

                <input
                  type="file"
                  id="pdfUpload"
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  disabled={loading}
                />
                <label htmlFor="pdfUpload" className="btn">
                  <img
                    src={upload_docs}
                    alt="Icono de Subir"
                    className="upload-icon"
                  />{" "}
                  {t("pdf_placeholder")}
                </label>

                <div className={file ? "product-uploaded" : ""}>
                  <div>
                    {file && <img src={check} alt="" />}
                    {file && file?.name}
                  </div>
                  <span onClick={handleExitBtn}>
                    {file && <img src={exit} alt="" />}
                  </span>
                </div>
              </div>

              <div className="button-section">
                <button
                  className="btn-classify"
                  onClick={handleSecondButtonClick}
                  disabled={loading}
                >
                  {t("btn_get_ranking")}
                </button>
              </div>

              {response && <h2 id="result">⬇️ {t("results")}</h2>}

              <br />
              {/* {!response && <p>Aqui veras tu clasificación.</p>} */}
              {loading && <p>{t("loading_p")}</p>}
              <br />
              <ul>
                {response?.map((item, index) => (
                  <li className="border fs-5 text" key={index}>
                    <div className="d-flex flex-row justify-content-between gap-2 w-100">
                      <div className="d-flex flex-column w-50">
                        <span className="d-flex flex-row gap-2">
                          <strong>{t("result_ranking")}:</strong>{" "}
                          <span>{item.fraction.code}</span>
                          {/* Copiar código */}
                          <i
                            className="bi bi-copy ms-3 cursor-pointer"
                            onClick={() => handleCopyCode(item.fraction.code)}
                          ></i>
                        </span>
                        <span className="d-flex flex-row gap-2">
                          <strong>{t("result_name")}:</strong>{" "}
                          {item.fraction.name}
                        </span>
                      </div>

                      {/* Etiqueta precision */}
                      <div className="d-flex flex-row justify-content-end gap-2 w-50">
                        <span
                          className={`reliability w-50 text-center ${
                            item.overall_confidence >= 0 &&
                            item.overall_confidence <= 9
                              ? "bg-danger"
                              : item.overall_confidence > 9 &&
                                item.overall_confidence <= 49
                              ? "bg-warning-subtle"
                              : item.overall_confidence > 49 &&
                                item.overall_confidence <= 69
                              ? "bg-warning"
                              : item.overall_confidence > 69 &&
                                item.overall_confidence <= 91
                              ? "bg-success"
                              : item.overall_confidence > 91 &&
                                item.overall_confidence <= 100
                              ? "bg-info"
                              : ""
                          }`}
                        >
                          {item.overall_confidence >= 0 &&
                          item.overall_confidence <= 9
                            ? `${item.overall_confidence}% Sin resultado`
                            : item.overall_confidence > 9 &&
                              item.overall_confidence <= 49
                            ? `${item.overall_confidence}% Baja confianza`
                            : item.overall_confidence > 49 &&
                              item.overall_confidence <= 69
                            ? `${item.overall_confidence}% Confianza media`
                            : item.overall_confidence > 69 &&
                              item.overall_confidence <= 91
                            ? `${item.overall_confidence}% Confianza alta`
                            : item.overall_confidence > 91 &&
                              item.overall_confidence <= 100
                            ? `${item.overall_confidence}% Muy alta confianza`
                            : ""}
                        </span>
                      </div>
                      {/* Etiqueta precision */}
                    </div>
                    <section className="d-flex flex-row w-100 pt-3 font-gray-strong strong">
                      <span className="w-50">Sección</span>
                      <span className="w-100">Descripción</span>
                    </section>
                    <hr className="my-1" />
                    {/* Sección partidas */}
                    <section className="d-flex flex-column column-gap">
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.chapter.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Capítulo
                            </p>
                          </div>
                          <div class="d-flex align-items-center justify-content-center w-50"></div>
                        </span>
                        <span className="w-100 fs-6">{item.chapter.name}</span>
                      </div>
                      <hr className="my-1" />
                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.heading.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Partida
                            </p>
                          </div>
                          <div class="d-flex align-items-center justify-content-center w-50">
                            {showNearly === index && partida === "heading" ? (
                              <i
                                className="bi bi-chevron-contract"
                                onClick={() =>
                                  toggleShowNearly(index, "heading")
                                }
                              ></i>
                            ) : (
                              <i
                                className="bi bi-chevron-expand"
                                onClick={() =>
                                  toggleShowNearly(index, "heading")
                                }
                              ></i>
                            )}
                          </div>
                        </span>
                        <span className="w-100 fs-6">{item.heading.name}</span>
                      </div>
                      {/* Sección nearly */}
                      <div
                        className={`${
                          showNearly === index && partida === "heading"
                            ? "d-block"
                            : "d-none"
                        } w-100`}
                      >
                        <TariffNearly
                          tariff={item.fraction.code}
                          nearly="partida"
                          nearlyRef={item.heading.code}
                        />
                      </div>
                      {/* Sección nearly */}
                      <hr className="my-1" />

                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.subheading.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Subpartida
                            </p>
                          </div>
                          <div class="d-flex align-items-center justify-content-center w-50">
                            {showNearlySubheading === index &&
                            subPartida === "subheading" ? (
                              <i
                                className="bi bi-chevron-contract"
                                onClick={() =>
                                  toggleShowNearlySubheading(
                                    index,
                                    "subheading"
                                  )
                                }
                              ></i>
                            ) : (
                              <i
                                className="bi bi-chevron-expand"
                                onClick={() =>
                                  toggleShowNearlySubheading(
                                    index,
                                    "subheading"
                                  )
                                }
                              ></i>
                            )}
                          </div>
                        </span>
                        <span className="w-100 fs-6">
                          {item.subheading.name}
                        </span>
                      </div>
                      {/* Sección nearly */}
                      <div
                        className={`${
                          showNearlySubheading === index &&
                          subPartida === "subheading"
                            ? "d-block"
                            : "d-none"
                        } w-100`}
                      >
                        <TariffNearly
                          tariff={item.fraction.code}
                          nearly="subpartida"
                          nearlyRef={item.subheading.code}
                        />
                      </div>
                      {/* Sección nearly */}
                      <hr className="my-1" />

                      <div className="d-flex flex-row column-gap align-items-center">
                        <span className="d-flex flex-row column-gap w-50">
                          <div class="d-flex flex-column w-50">
                            <p className="fs-5 text">{item.fraction.code}</p>
                            <p className="fw-semibold text-gray fs-5 text">
                              Fracción
                            </p>
                          </div>
                          <div class="d-flex align-items-center justify-content-center w-50">
                            {showNearlyFraction === index &&
                            fraction === "fraction" ? (
                              <i
                                className="bi bi-chevron-contract"
                                onClick={() =>
                                  toggleShowNearlyFraction(index, "fraction")
                                }
                              ></i>
                            ) : (
                              <i
                                className="bi bi-chevron-expand"
                                onClick={() =>
                                  toggleShowNearlyFraction(index, "fraction")
                                }
                              ></i>
                            )}
                          </div>
                        </span>
                        <span className="w-100 fs-6">{item.fraction.name}</span>
                      </div>
                    </section>
                    {/* Sección nearly */}
                    <div
                      className={`${
                        showNearlyFraction === index && fraction === "fraction"
                          ? "d-block"
                          : "d-none"
                      } w-100`}
                    >
                      <TariffNearly
                        tariff={item.fraction.code}
                        nearly="fracciones"
                        nearlyRef={item.fraction.code}
                      />
                    </div>
                    {/* Sección nearly */}
                    <hr className="my-1" />

                    {/* Sección noms =>*/}
                    <TariffNoms tariff={item.fraction.code} />

                    {/* Secci+on likes */}
                    <section className="d-flex flex-row justify-content-between align-items-end w-100">
                      <div className="d-flex flex-row">
                        <span className="m-2 cursor-pointer">
                          <i className="bi bi-hand-thumbs-up"></i>
                        </span>
                        <span className="m-2 cursor-pointer">
                          <i className="bi bi-hand-thumbs-down"></i>
                        </span>
                      </div>
                      <button
                        className="btn-camtom-rounded"
                        onClick={() =>
                          handleRedirectToQuoter(item.fraction.code)
                        }
                      >
                        {t("quote_customs_clearance")}
                      </button>
                    </section>
                    {/* Secci+on likes */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* {alertMessage && (
            <div
              class={`position-fixed top-50 start-50 translate-middle alert py-5 px-5 shadow-lg p-3 mb-5 rounded ${
                setShowAlert ? "alert-success" : "alert-success"
              }`}
              role="alert"
            >
              {alertMessage}
            </div>
          )} */}
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      )}
    </>
  );
};

export default TariffPro;
