import { useNavigate } from "react-router-dom";
import "../../../style/Quoter.css";
import { useTranslation } from "react-i18next";

function QuoterInfoView({ response, typeOperation, handleReturn }) {
  const { t } = useTranslation(); // i18n // traduccion

  console.log(typeOperation, "desde vista info");

  return (
    <div class="w-100 h-100 d-flex flex-column p-5 gap-4">
      <div className="d-flex flex-row align-items-center justify-content-start border-bottom">
        <i
          className="bi bi-arrow-left fs-2 me-4 cursor"
          onClick={handleReturn}
        ></i>
        <h1 className="my-2">{t("quote_report")}</h1>
        {/* <h1>{t("edit_fraction")}</h1> */}
      </div>
      <div class="d-flex flex-row w-100 gap-3 pb-5">
        <div class="d-flex flex-column gap-3" style={{ width: "60%" }}>
          <div class="d-flex flex-column border border-2 rounded-4  p-4">
            <section class="d-flex flex-row justify-content-between w-100 py-2">
              <h5>Gastos y Pagos Arancelarios</h5>
              <h5>
                <strong>${response.grand_total_taxes}</strong>
              </h5>
            </section>
            {response.products_taxes?.map((e, index) => (
              <div class="d-flex p-2" key={index}>
                <section class="d-flex flex-column w-100 gap-2" key={index}>
                  <span class="d-flex flex-column justify-content-between">
                    <small className="strong font-gray-light">
                      {`Partida ${index + 1}`}
                    </small>
                    <hr />
                    <p className="strong font-gray-light">
                      {t("tariff_fraction")}
                    </p>
                    <p>{e.fraction}</p>
                  </span>
                  {typeOperation === "expo" && (
                    <span class="d-flex flex-row justify-content-between">
                      <p>IGE</p>
                      <p>${e.ige}</p>
                    </span>
                  )}
                  {typeOperation === "impo" && (
                    <span class="d-flex flex-row justify-content-between">
                      <p>IGI</p>
                      <p>${e.igi}</p>
                    </span>
                  )}
                  {e.cuota_compensatoria ? (
                    <span class="d-flex flex-row justify-content-between">
                      <p>Cuota compensatoria</p>
                      <p>${e.cuota_compensatoria}</p>
                    </span>
                  ) : (
                    <span class="d-flex flex-row justify-content-between">
                      <p>Cuota compensatoria</p>
                      <p>No aplica</p>
                    </span>
                  )}
                  <span class="d-flex flex-row justify-content-between">
                    <p>IVA</p>
                    <p>${e.iva}</p>
                  </span>
                  {e.noms.length > 0 && (
                    <span class="d-flex flex-column justify-content-between">
                      <p className="strong font-gray-light">NOMs</p>
                      {e.noms?.map((el, index) => (
                        <div key={index}>
                          <p className="border p-2 text-center rounded-pill font-gray-strong bg-gray-noms mt-3">
                            {el.NOM}
                          </p>
                          {el.Acotacion ? (
                            <small>{el.Acotacion}</small>
                          ) : (
                            "No se encontró acotación para esta NOM. Le recomendamos consultar fuentes oficiales como el Diario Oficial de la Federación (DOF)"
                          )}
                        </div>
                      ))}
                    </span>
                  )}
                  <span class="d-flex flex-column">
                    <p className="strong font-gray-light">
                      {t("preferential _tariff")}
                    </p>
                    {e.preferenciales_secretaria_economia.length > 0 && (
                      <div key={index}>
                        {e.preferenciales_secretaria_economia.map(
                          (item, idx) => (
                            <p
                              key={idx}
                              className="border w-50 p-2 text-center rounded-pill font-gray-strong bg-gray-noms"
                              style={{ fontSize: "0.6rem" }}
                            >
                              {item}
                            </p>
                          )
                        )}
                      </div>
                    )}
                    <p className="strong font-gray-light">{t("")}</p>
                    {e.rrnas_secretaria_economia.length > 0 && (
                      <div>
                        {e.rrnas_secretaria_economia.map((item, index) => (
                          <p
                            key={index}
                            className="border w-50 p-2 text-center rounded-pill font-gray-strong bg-gray-noms"
                            style={{ fontSize: "0.6rem" }}
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    )}
                    <p className="strong font-gray-light">{t("RRNAs")}</p>
                    {e.rrnas_otras_dependencias.length > 0 && (
                      <div>
                        {e.rrnas_otras_dependencias.map((item, index) => (
                          <p
                            key={index}
                            className="border w-50 p-2 text-center rounded-pill font-gray-strong bg-gray-noms"
                            style={{ fontSize: "0.6rem" }}
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    )}
                  </span>
                </section>
              </div>
            ))}
          </div>

          <div class="d-flex flex-column w-100 border border-2 rounded-4  p-4">
            <section class="d-flex flex-row justify-content-between w-50 py-2">
              <h5 className="strong">{t("income_of_customs_agent")}</h5>
            </section>

            <section class="d-flex flex-row justify-content-between w-100">
              <p>COVE</p>
              <p>${response.cove}</p>
            </section>

            <section class="d-flex flex-row justify-content-between w-100 py-2">
              <p>{t("digitization")}</p>
              <p>${response.digitalizacion}</p>
            </section>

            <section class="d-flex flex-row justify-content-between w-100">
              <p>Prevalidación</p>
              <p>${response.prev_con_iva}</p>
            </section>

            <section class="d-flex flex-row justify-content-between w-100">
              <p>DTA</p>
              <p>${response.dta}</p>
            </section>

            <section class="d-flex flex-row justify-content-between w-100">
              <p>Honorarios del Agente Aduanal</p>
              <p>No incluidos</p>
            </section>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default QuoterInfoView;
